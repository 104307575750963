import api_client from "./http-common";

/**
 * Asynchronously fetches user results from the backend API.
 *
 * This function sends a POST request to the `/user-results/` endpoint with filter parameters.
 * It retrieves and processes data related to user results, energy efficiency classes, and CO2 emission classes.
 *
 * Parameters:
 * - filter_type: A string indicating the type of filter to apply (e.g., 'all', 'best', 'worst').
 * - filter_choice: An array or other structure representing the specific filter choices (e.g., number of buildings).
 *
 * Returns:
 *   Object: An object containing arrays of user results, energy efficiency classes, and CO2 emission classes.
 */
export default async function fetchUserResults({ filter_type, filter_choice }) {
    const result = await api_client.post(`/user-results/`, {
        filter_type,
        filter_choice,
    });
    var preferredYear = await result.data.data.preferred_year;
    var userResultsArr = await result.data.data.results;
    var energyEfficiencyClassesArr = await result.data.data.energy_efficiency_classes;
    var co2EmissionClassesArr = await result.data.data.co2_emission_classes;
    var modernizationCosts = await result.data.data.modernization_costs;
    var modernizationCostsHeatingSystems = await result.data.data.modernization_costs_heating_systems;
    return {
        userResults: userResultsArr,
        preferredYear: preferredYear,
        energyEfficiencyClasses: energyEfficiencyClassesArr,
        co2EmissionClasses: co2EmissionClassesArr,
        modernizationCosts: modernizationCosts,
        modernizationCostsHeatingSystems: modernizationCostsHeatingSystems,
    };
}

export const ResultDataTypes = {
    Co2Emissions: "Co2Emissions",
    Co2EmissionsPerSqm: "Co2EmissionsPerSqm",
    GrossEnergyConsumption: "GrossEnergyConsumption",
    GrossEnergyConsumptionPerSqm: "GrossEnergyConsumptionPerSqm",
    SingleBuildingCo2Emissions: "SingleBuildingCo2Emissions",
    SingleBuildingCo2EmissionsPerSqm: "SingleBuildingCo2EmissionsPerSqm",
    SingleBuildingGrossEnergyConsumption: "SingleBuildingGrossEnergyConsumption",
    SingleBuildingGrossEnergyConsumptionPerSqm: "SingleBuildingGrossEnergyConsumptionPerSqm",
};

/**
 * Generates table data from user results based on the specified data type.
 *
 * This function processes user results to create a structured array of data suitable for display.
 * It supports different data types, each producing a specific structure of data.
 *
 * Parameters:
 * - datatype: A ResultDataTypes enum value specifying the type of data to generate.
 * - userResults: An array of user result objects to process.
 * - climateCleaned: A boolean indicating whether to apply climate factor adjustments to the data (default: false).
 *
 * Returns:
 *   Array: An array of objects, each representing a row of data for the table.
 *
 * Note:
 * - The function handles various data types like CO2 emissions, energy consumption, etc., for both total and per square meter calculations.
 * - For 'SingleBuilding' data types, it differentiates between actual data and forecast data.
 * - If the resulting data array has less than 10 items, it pads the array with empty data up to 10 items.
 */
export function getTableDataFromUserResults(datatype, userResults, climateCleaned = false) {
    var dataArray = [];
    switch (datatype) {
        case ResultDataTypes.Co2Emissions:
            dataArray = userResults.map((data) => {
                return {
                    year: data.year,
                    "Raumwärme (Verbräuche)": Math.round(
                        data.total_co2_emissions_roomheating *
                            (climateCleaned ? data.weighted_climate_factor_co2_emissions_roomheating : 1)
                    ),
                    "Warmwasser (Verbräuche)": data.total_co2_emissions_waterheating,
                    Solar: -data.total_pv_co2_savings,
                    "Raumwärme (Prognose)": Math.round(
                        data.forecast_total_co2_emissions_roomheating *
                            (climateCleaned ? data.weighted_climate_factor_co2_emissions_roomheating : 1)
                    ),
                    "Warmwasser (Prognose)": data.forecast_total_co2_emissions_waterheating,
                };
            });
            break;

        case ResultDataTypes.Co2EmissionsPerSqm:
            dataArray = userResults.map((data) => {
                return {
                    year: data.year,
                    "Raumwärme (Verbräuche)": (
                        data.mixed_data_total_co2_emissions_roomheating_per_sqm *
                        (climateCleaned ? data.weighted_climate_factor_co2_emissions_roomheating : 1)
                    ).toFixed(1),
                    "Warmwasser (Verbräuche)": data.mixed_data_total_co2_emissions_waterheating_per_sqm,
                    Solar: -data.total_pv_co2_savings_per_sqm,
                    "Raumwärme (Prognose)": (
                        data.forecast_total_co2_emissions_roomheating_per_sqm *
                        (climateCleaned ? data.weighted_climate_factor_co2_emissions_roomheating : 1)
                    ).toFixed(1),
                    "Warmwasser (Prognose)": data.forecast_total_co2_emissions_waterheating_per_sqm,
                };
            });
            break;

        case ResultDataTypes.GrossEnergyConsumption:
            dataArray = userResults.map((data) => {
                return {
                    year: data.year,
                    "Raumwärme (Verbräuche)": Math.round(
                        data.total_gross_energy_consumption_roomheating *
                            (climateCleaned ? data.weighted_climate_factor_consumption_roomheating : 1)
                    ),
                    "Warmwasser (Verbräuche)": data.total_gross_energy_consumption_waterheating,
                    Solar: -data.total_pv_yield,
                    "Raumwärme (Prognose)": Math.round(
                        data.forecast_total_gross_energy_consumption_roomheating *
                            (climateCleaned ? data.weighted_climate_factor_consumption_roomheating : 1)
                    ),
                    "Warmwasser (Prognose)": data.forecast_total_gross_energy_consumption_waterheating,
                };
            });
            break;

        case ResultDataTypes.GrossEnergyConsumptionPerSqm:
            dataArray = userResults.map((data) => {
                return {
                    year: data.year,
                    "Raumwärme (Verbräuche)": (
                        data.mixed_data_total_gross_energy_consumption_roomheating_per_sqm *
                        (climateCleaned ? data.weighted_climate_factor_consumption_roomheating : 1)
                    ).toFixed(1),
                    "Warmwasser (Verbräuche)": data.mixed_data_total_gross_energy_consumption_waterheating_per_sqm,
                    Solar: -data.total_pv_yield_per_sqm,
                    "Raumwärme (Prognose)": (
                        data.forecast_total_gross_energy_consumption_roomheating_per_sqm *
                        (climateCleaned ? data.weighted_climate_factor_consumption_roomheating : 1)
                    ).toFixed(1),
                    "Warmwasser (Prognose)": data.forecast_total_gross_energy_consumption_waterheating_per_sqm,
                };
            });
            break;

        case ResultDataTypes.SingleBuildingCo2Emissions:
            dataArray = userResults.map((data) => {
                if (data.only_forecast_data) {
                    return {
                        year: data.year,
                        "Raumwärme (Verbräuche)": 0,
                        "Warmwasser (Verbräuche)": 0,
                        Solar: -data.total_pv_co2_savings,
                        "Raumwärme (Prognose)": Math.round(
                            data.total_co2_emissions_roomheating * (climateCleaned ? data.climate_factor : 1)
                        ),
                        "Warmwasser (Prognose)": data.total_co2_emissions_waterheating,
                    };
                } else {
                    return {
                        year: data.year,
                        "Raumwärme (Verbräuche)": Math.round(
                            data.total_co2_emissions_roomheating * (climateCleaned ? data.climate_factor : 1)
                        ),
                        "Warmwasser (Verbräuche)": data.total_co2_emissions_waterheating,
                        Solar: -data.total_pv_co2_savings,
                        "Raumwärme (Prognose)": 0,
                        "Warmwasser (Prognose)": 0,
                    };
                }
            });
            break;

        case ResultDataTypes.SingleBuildingCo2EmissionsPerSqm:
            dataArray = userResults.map((data) => {
                if (data.only_forecast_data) {
                    return {
                        year: data.year,
                        "Raumwärme (Verbräuche)": 0,
                        "Warmwasser (Verbräuche)": 0,
                        Solar: -data.total_pv_co2_savings_per_sqm,
                        "Raumwärme (Prognose)": (
                            data.total_co2_emissions_roomheating_per_sqm * (climateCleaned ? data.climate_factor : 1)
                        ).toFixed(1),
                        "Warmwasser (Prognose)": data.total_co2_emissions_waterheating_per_sqm,
                    };
                } else {
                    return {
                        year: data.year,
                        "Raumwärme (Verbräuche)": (
                            data.total_co2_emissions_roomheating_per_sqm * (climateCleaned ? data.climate_factor : 1)
                        ).toFixed(1),
                        "Warmwasser (Verbräuche)": data.total_co2_emissions_waterheating_per_sqm,
                        Solar: -data.total_pv_co2_savings_per_sqm,
                        "Raumwärme (Prognose)": 0,
                        "Warmwasser (Prognose)": 0,
                    };
                }
            });
            break;

        case ResultDataTypes.SingleBuildingGrossEnergyConsumption:
            dataArray = userResults.map((data) => {
                if (data.only_forecast_data) {
                    return {
                        year: data.year,
                        "Raumwärme (Verbräuche)": 0,
                        "Warmwasser (Verbräuche)": 0,
                        Solar: -data.total_pv_yield,
                        "Raumwärme (Prognose)": Math.round(
                            data.total_gross_energy_consumption_roomheating * (climateCleaned ? data.climate_factor : 1)
                        ),
                        "Warmwasser (Prognose)": data.total_gross_energy_consumption_waterheating,
                    };
                } else {
                    return {
                        year: data.year,
                        "Raumwärme (Verbräuche)": Math.round(
                            data.total_gross_energy_consumption_roomheating * (climateCleaned ? data.climate_factor : 1)
                        ),
                        "Warmwasser (Verbräuche)": data.total_gross_energy_consumption_waterheating,
                        Solar: -data.total_pv_yield,
                        "Raumwärme (Prognose)": 0,
                        "Warmwasser (Prognose)": 0,
                    };
                }
            });
            break;

        case ResultDataTypes.SingleBuildingGrossEnergyConsumptionPerSqm:
            dataArray = userResults.map((data) => {
                if (data.only_forecast_data) {
                    return {
                        year: data.year,
                        "Raumwärme (Verbräuche)": 0,
                        "Warmwasser (Verbräuche)": 0,
                        Solar: -data.total_pv_yield_per_sqm,
                        "Raumwärme (Prognose)": (
                            data.total_gross_energy_consumption_roomheating_per_sqm *
                            (climateCleaned ? data.climate_factor : 1)
                        ).toFixed(1),
                        "Warmwasser (Prognose)": data.total_gross_energy_consumption_waterheating_per_sqm,
                    };
                } else {
                    return {
                        year: data.year,
                        "Raumwärme (Verbräuche)": (
                            data.total_gross_energy_consumption_roomheating_per_sqm *
                            (climateCleaned ? data.climate_factor : 1)
                        ).toFixed(1),
                        "Warmwasser (Verbräuche)": data.total_gross_energy_consumption_waterheating_per_sqm,
                        Solar: -data.total_pv_yield_per_sqm,
                        "Raumwärme (Prognose)": 0,
                        "Warmwasser (Prognose)": 0,
                    };
                }
            });
            break;

        default:
            break;
    }
    if (dataArray.length < 10) {
        for (var i = dataArray.length; i < 10; i++) {
            dataArray.push({ year: dataArray[dataArray.length - 1].year + 1, Raumwärme: 0, Warmwasser: 0 });
        }
    }
    return dataArray;
}


export function getEnergyEfficiencyClassDataFromUserResults(userResults, year = null, include_forecast = false) {
    /*
    userResults Format:
    [{
        year: 2021,	
        energy_efficiency_classes: {
        real: {count: {A: 0, B: 1, ...}, living_space: A: 0, B: 123, ...}
        forecast: {count: {A: 0, B: 1, ...}, living_space: A: 0, B: 123, ...}
        real_climate_cleaned: {count: {A: 0, B: 1, ...}, living_space: A: 0, B: 123, ...}
        forecast_climate_cleaned: {count: {A: 0, B: 1, ...}, living_space: A: 0, B: 123, ...}
        },
        ...
    }, ...] 
    */

    var res
    /* --------------- Case only single year --------------- */
    // filter year
    if (year !== null) {
        userResults = userResults.filter((result) => result.year === year);
    
        var result = userResults[0];

        // check if we should normal or climate cleaned data
        var realData = result.energy_efficiency_classes.real;
        var forecastData = result.energy_efficiency_classes.forecast;
        var realDataClimateCleaned = result.energy_efficiency_classes.real_climate_cleaned;
        var forecastDataClimateCleaned = result.energy_efficiency_classes.forecast_climate_cleaned;

        // if we should include forecast data, we need to merge the real and forecast data
        if (include_forecast) {
            realData = Object.keys(realData.count).reduce((acc, key) => {
                acc.count[key] = realData.count[key] + forecastData.count[key];
                acc.living_space[key] = realData.living_space[key] + forecastData.living_space[key];
                return acc;
            }, {});
            realDataClimateCleaned = Object.keys(realDataClimateCleaned.count).reduce((acc, key) => {
                acc.count[key] = realDataClimateCleaned.count[key] + forecastDataClimateCleaned.count[key];
                acc.living_space[key] = realDataClimateCleaned.living_space[key] + forecastDataClimateCleaned.living_space[key];
                return acc;
            }, {});
        }
        
        // get the sum of living space value
        var sumLivingSpace = Object.values(realData.living_space).reduce((acc, value) => acc + value, 0); // get the sum of living space value
        var sumLivingSpaceClimateCleaned = Object.values(realDataClimateCleaned.living_space).reduce((acc, value) => acc + value, 0); // get the sum of living space value
        // get the sum of count value
        var sumCount = Object.values(realData.count).reduce((acc, value) => acc + value, 0); // get the sum of count value
        var sumCountClimateCleaned = Object.values(realDataClimateCleaned.count).reduce((acc, value) => acc + value, 0); // get the sum of count value

        res = {
            climateCleaned: [],
            regular: [],
        };

        // get the data for the energy efficiency classes
        Object.keys(realData.count).forEach((key) => {
            res.regular.push({
                name: key,
                "Gebäude [%]": Math.round(realData.count[key] / sumCount * 100),
                "Wohnfläche [%]": Math.round(realData.living_space[key] / sumLivingSpace * 100),
                "Gebäude [#]":  Math.round(realData.count[key]),
                "Wohnfläche [m²]": Math.round(realData.living_space[key]),
            }
        );
        });

        // get the data for the energy efficiency classes (climate cleaned)
        Object.keys(realDataClimateCleaned.count).forEach((key) => {
            res.climateCleaned.push({
                name: key,
                "Gebäude [%]": Math.round(realDataClimateCleaned.count[key] / sumCountClimateCleaned * 100),
                "Wohnfläche [%]": Math.round(realDataClimateCleaned.living_space[key] / sumLivingSpaceClimateCleaned * 100),
                "Gebäude [#]":  Math.round(realDataClimateCleaned.count[key]),
                "Wohnfläche [m²]": Math.round(realDataClimateCleaned.living_space[key]),
            });
        });

        // sort such that A+ is in front
        res.regular.sort((a, b) => {
            if (a.name === "A+") {
                return -1;
            }
            if (b.name === "A+") {
                return 1;
            }
            return a.name.localeCompare(b.name);
        });
        res.climateCleaned.sort((a, b) => {
            if (a.name === "A+") {
                return -1;
            }
            if (b.name === "A+") {
                return 1;
            }
            return a.name.localeCompare(b.name);
        });

        // return data for single year
        return res;
    } else {
        /* --------------- Case all years --------------- */
        res = {
            climateCleaned: {count: [], livingSpace: [], countPercentage: [], livingSpacePercentage: []},
            regular: {count: [], livingSpace: [], countPercentage: [], livingSpacePercentage: []},
        };

        // iterate over all years
        userResults.forEach((result, year_index) => {
            // check if we should normal or climate cleaned data
            var realData = result.energy_efficiency_classes.real;
            var forecastData = result.energy_efficiency_classes.forecast;
            var realDataClimateCleaned = result.energy_efficiency_classes.real_climate_cleaned;
            var forecastDataClimateCleaned = result.energy_efficiency_classes.forecast_climate_cleaned;

            // if we should include forecast data, we need to merge the real and forecast data
            if (include_forecast) {
                realData = Object.keys(forecastData.count).reduce((acc, key) => {
                    acc.count[key] = realData.count[key] + forecastData.count[key];
                    acc.living_space[key] = realData.living_space[key] + forecastData.living_space[key];
                    return acc;
                }, {count: {}, living_space: {}});
                realDataClimateCleaned = Object.keys(realDataClimateCleaned.count).reduce((acc, key) => {
                    acc.count[key] = realDataClimateCleaned.count[key] + forecastDataClimateCleaned.count[key];
                    acc.living_space[key] = realDataClimateCleaned.living_space[key] + forecastDataClimateCleaned.living_space[key];
                    return acc;
                }, {count: {}, living_space: {}});
            }

            // get the sum of living space value
            var sumLivingSpace = Object.values(realData.living_space).reduce((acc, value) => acc + value, 0); // get the sum of living space value
            var sumLivingSpaceClimateCleaned = Object.values(realDataClimateCleaned.living_space).reduce((acc, value) => acc + value, 0); // get the sum of living space value
            // get the sum of count value
            var sumCount = Object.values(realData.count).reduce((acc, value) => acc + value, 0); // get the sum of count value
            var sumCountClimateCleaned = Object.values(realDataClimateCleaned.count).reduce((acc, value) => acc + value, 0); // get the sum of count value

            // add year info to each array
            res.regular.count.push({year: result.year});
            res.regular.livingSpace.push({year: result.year});
            res.regular.countPercentage.push({year: result.year});
            res.regular.livingSpacePercentage.push({year: result.year});
            res.climateCleaned.count.push({year: result.year});
            res.climateCleaned.livingSpace.push({year: result.year});
            res.climateCleaned.countPercentage.push({year: result.year});
            res.climateCleaned.livingSpacePercentage.push({year: result.year});

            // // sort such that A+ is in front
            // res.regular.count[year_index].sort((a, b) => {
            //     return a.name === "A+" ?  -1 : b.name === "A+" ?  1 : a.name.localeCompare(b.name);
            // });
            // res.regular.livingSpace[year_index].sort((a, b) => {
            //     return a.name === "A+" ?  -1 : b.name === "A+" ?  1 : a.name.localeCompare(b.name);
            // });
            // res.regular.countPercentage[year_index].sort((a, b) => {
            //     return a.name === "A+" ?  -1 : b.name === "A+" ?  1 : a.name.localeCompare(b.name);
            // });
            // res.regular.livingSpacePercentage[year_index].sort((a, b) => {
            //     return a.name === "A+" ?  -1 : b.name === "A+" ?  1 : a.name.localeCompare(b.name);
            // });
            // res.climateCleaned.count.sort((a, b) => {
            //     return a.name === "A+" ?  -1 : b.name === "A+" ?  1 : a.name.localeCompare(b.name);
            // });
            // res.climateCleaned.livingSpace.sort((a, b) => {
            //     return a.name === "A+" ?  -1 : b.name === "A+" ?  1 : a.name.localeCompare(b.name);
            // });
            // res.climateCleaned.countPercentage[year_index].sort((a, b) => {
            //     return a.name === "A+" ?  -1 : b.name === "A+" ?  1 : a.name.localeCompare(b.name);
            // });
            // res.climateCleaned.livingSpacePercentage[year_index].sort((a, b) => {
            //     return a.name === "A+" ?  -1 : b.name === "A+" ?  1 : a.name.localeCompare(b.name);
            // });

            // add info to each array
            Object.keys(realData.count).forEach((key) => {
                res.regular.count[year_index][key] = Math.round(realData.count[key]);
                res.regular.livingSpace[year_index][key] = Math.round(realData.living_space[key]);
                res.regular.countPercentage[year_index][key] = Math.round(realData.count[key] / sumCount * 100);
                res.regular.livingSpacePercentage[year_index][key] = Math.round(realData.living_space[key] / sumLivingSpace * 100);
                res.climateCleaned.count[year_index][key] = Math.round(realDataClimateCleaned.count[key]);
                res.climateCleaned.livingSpace[year_index][key] = Math.round(realDataClimateCleaned.living_space[key]);
                res.climateCleaned.countPercentage[year_index][key] = Math.round(realDataClimateCleaned.count[key] / sumCountClimateCleaned * 100);
                res.climateCleaned.livingSpacePercentage[year_index][key] = Math.round(realDataClimateCleaned.living_space[key] / sumLivingSpaceClimateCleaned * 100);

            });
        });

        return res;
    }
}