/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useContext } from "react";
import { useImmer } from "use-immer";
import { Typography, InputAdornment, Box, Fab, Modal, Divider, Grid, Button, ToggleButton} from "@mui/material";
import api_client from "../../../services/http-common";
import { useTheme } from "@mui/material/styles";
import { addDisplayNumbersInJson, cleanNumbersInJson,createGermanNumberString } from "../../../utils/formatting";
import ToastContext from "../../../contexts/ToastContext";
import CloseIcon from "@mui/icons-material/Close";
import { CustomTooltip } from "../../../components/CustomTooltip";
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import SaveIcon  from "@mui/icons-material/Save";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import InsightsTwoToneIcon from "@mui/icons-material/InsightsTwoTone";
import { FloatInput } from "../../../components/InputFields";
import EditNoteIcon from "@mui/icons-material/EditNote";

// check object (may contain arrays or other objects) recursively for false values
const checkObjectForTrue = (obj) => {
    for (var key in obj) {
        if (obj[key] === true) {
            return true;
        }
        if (typeof obj[key] === "object") {
            if (checkObjectForTrue(obj[key])) {
                return true;
            }
        }
    }
    return false;
};

const HeatingSystemsUserInputModernizationCostsModal = ({ currentUserInputCosts, userInputModernizationCostsModalOpen, setUserInputModernizationCostsModalOpen, currentBuilding, buildingHeatingSystemsState, buildingHeatingSystemsStateTab, setBuildingHeatingSystemsState}) => {
    const { addToastMessage } = useContext(ToastContext);

    const [userInputCosts, setUserInputCosts] = useImmer(
        currentUserInputCosts
            ? { ...currentUserInputCosts }
            : addDisplayNumbersInJson({ cost_heating_systems: 0 })
    );

    const [errorUserInputCosts, setErrorUserInputCosts] = useImmer({
        cost_heating_systems: false,
    });

    const [fetchingData, setFetchingData] = useState(false);

    // handle the saving of the user input modernization costs
    const handleSaveUserInputModernizationCosts = async () => {
        setFetchingData(true);
        var _userInputCosts = cleanNumbersInJson(userInputCosts);

        // post userinput modernization costs to backend
        api_client
            .post(
                `/buildings/${currentBuilding.id}/heating-systems-states/${buildingHeatingSystemsState[buildingHeatingSystemsStateTab].id}/modernization-costs/`,
                {
                    ..._userInputCosts,
                }
            )
            .then((response) => {
                const modernization_costs_status = response.status;
                if (modernization_costs_status === 200) {
                    const data = addDisplayNumbersInJson(response.data.data);
                    setBuildingHeatingSystemsState((cond) => {
                        cond[buildingHeatingSystemsStateTab].modernization_costs.user_input = data;
                    });
                }
                setFetchingData(false);
            })
            .catch((error) => {
                addToastMessage("Fehler beim Speichern der Modernisierungskosten.", "error");
                setFetchingData(false);
            });
    };

    return (
        <Modal
            open={userInputModernizationCostsModalOpen}
            onClose={() => setUserInputModernizationCostsModalOpen(false)}
            aria-labelledby="user-input-heating-systems-modernization-costs-modal"
        >
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "60ch",
                    bgcolor: "background.paper",
                    border: "2px solid #000",
                    boxShadow: 24,
                    p: 4,
                }}
            >
            {fetchingData && LoadingSpinner}
            <Button
                    sx={{
                        position: "absolute",
                        top: "10px",
                        right: "5px",
                    }}
                    onClick={() => setUserInputModernizationCostsModalOpen(false)}
                >
                    <CloseIcon />
                </Button>
                <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mb: 1 }}>
                    Geben Sie, sofern vorhanden, die entstandenen Kosten für die Modernisierung der Wärmeversorgungsanlagen im Jahr {buildingHeatingSystemsState[buildingHeatingSystemsStateTab].year} ein
                </Typography>
                <Box height="7ch">
                    {/* Float Input für Heizungsanlagen Kosten */}
                    <FloatInput
                        value={userInputCosts.cost_heating_systems}
                        variant="outlined"
                        onChange={(val) => {
                            setUserInputCosts((prev) => {
                                prev.cost_heating_systems = val;
                            });
                        }}
                        sx={{ mt: 1, mr: 2, width: "46%" }}
                        size="small"
                        label="Kosten Wärmeversorgungsanlagen"
                        InputProps={{
                            startAdornment: <InputAdornment position="start">€</InputAdornment>,
                        }}
                        validate={(val) => {
                            return true;
                        }}
                        error={errorUserInputCosts.cost_heating_systems}
                        setError={(val) => {
                            setErrorUserInputCosts((err) => {
                                err.cost_heating_systems = val;
                            });
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        ml: "-8%",
                        mt: 1,
                    }}
                >
                    <Fab
                        variant="extended"
                        size="medium"
                        onClick={(e) => {
                            setBuildingHeatingSystemsState((cond) => {
                                cond[buildingHeatingSystemsStateTab].modernization_costs.userinput = userInputCosts;
                            });
                            handleSaveUserInputModernizationCosts();
                            setUserInputModernizationCostsModalOpen(false);
                        }}
                        color="secondary"
                        sx={{ ml: 3, mt: 0.8, boxShadow: 1, borderRadius: 1, width: "28ch" }}
                        disabled={
                            checkObjectForTrue(errorUserInputCosts) || Object.keys(errorUserInputCosts).length === 0
                        }
                    >
                        <SaveIcon sx={{ mr: 1 }} />
                        Speichern
                    </Fab>
                </Box>
            </Box>
        </Modal>
    );
};

export const HeatingSystemsModernizationCostsBox = ({ currentBuilding, buildingHeatingSystemsState, buildingHeatingSystemsStateTab, setBuildingHeatingSystemsState }) => {
    
    const [userInputModernizationCostsModalOpen, setUserInputModernizationCostsModalOpen] = useState(false);

    const theme = useTheme();

    return (
        <>
        {userInputModernizationCostsModalOpen && (
            <HeatingSystemsUserInputModernizationCostsModal
                userInputModernizationCostsModalOpen={userInputModernizationCostsModalOpen}
                setUserInputModernizationCostsModalOpen={setUserInputModernizationCostsModalOpen}
                currentUserInputCosts={
                    buildingHeatingSystemsState[buildingHeatingSystemsStateTab].modernization_costs.user_input
                }
                currentBuilding={currentBuilding}
                buildingHeatingSystemsState={buildingHeatingSystemsState}
                buildingHeatingSystemsStateTab={buildingHeatingSystemsStateTab}
                setBuildingHeatingSystemsState={setBuildingHeatingSystemsState}
            />
        )
        }
        <Box
            sx={{
                width: "35ch",
                m: 1,
                ml: 2,
                height: "202px",
                border: "lightgray",
                borderStyle: "solid",
                borderWidth: "1px",
                // borderRadius: "5px",
                borderTop: "1px solid rgba(0, 0, 0, .125)",
            }}
        >
            <Grid
                container
                sx={{
                    width: "100%",
                    backgroundColor:
                        theme.palette.mode === "dark"
                            ? "rgba(255, 255, 255, .05)"
                            : "rgba(0, 0, 0, .03)",
                    height: 60,
                    borderBottom: "1px solid rgba(0, 0, 0, .125)",
                }}
            >
                <Box sx={{ width: "92%" }}>
                    <Typography
                        sx={{
                            pl: 2, pt: 2, fontSize: 20, fontWeight: "bold"
                        }}
                    >
                        Modernisierungskosten
                    </Typography>
                </Box>
                <Box sx={{ width: "8%", mt: 1 }}>
                    <Fab
                        size="small"
                        onClick={() => setUserInputModernizationCostsModalOpen(true)}
                        sx={{
                            mt: 0.5,
                            ml: -1,
                            height: "3.0ch",
                            width: "3.6ch",
                            borderRadius: 1,
                            boxShadow: 0,
                            border: "1px solid lightgrey",
                            backgroundColor: "white",
                            zIndex: 0,
                        }}
                    >
                        <CustomTooltip
                            title={
                                <>
                                    Wenn Sie Ihre tatsächlich entstandenen
                                    Modernisierungskosten festhalten möchten,
                                    klicken Sie hier.
                                </>
                            }
                        >
                            <SaveAsIcon
                                sx={{
                                    ml: 0.2,
                                    color: theme.palette.secondary.main,
                                    fontSize: 22,
                                }}
                            />
                        </CustomTooltip>
                    </Fab>
                </Box>
            </Grid>
            <Grid container>
                {/* Topleft */}
                <Box
                    sx={{
                        width: "17ch",
                        padding: 1,
                    }}
                >
                    <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                        Geschätzte Kosten
                    </Typography>
                    <Typography sx={{ fontSize: 13 }}>
                    {buildingHeatingSystemsState[buildingHeatingSystemsStateTab]
                                    .modernization_costs.estimated
                            ? buildingHeatingSystemsState[buildingHeatingSystemsStateTab]
                                    .modernization_costs.estimated.cost_heating_systems.display +
                                "€"
                            : "N/V"}
                    </Typography>
                </Box>
                <Divider orientation="vertical" flexItem />
                {/* Topright */}
                <Box
                    sx={{
                        width: "17ch",
                        padding: 1,
                    }}
                >
                    <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                        Geschätzte Kosten je m²
                    </Typography>
                    <Typography sx={{ fontSize: 13 }}>
                        {buildingHeatingSystemsState[buildingHeatingSystemsStateTab]
                                    .modernization_costs.estimated
                            ? buildingHeatingSystemsState[buildingHeatingSystemsStateTab]
                                    .modernization_costs.estimated.cost_heating_systems_per_sqm.display +
                                "€"
                            : "N/V"}
                    </Typography>
                </Box>
                <Divider orientation="horizontal" flexItem sx={{ width: "100%" }} />
                {/* Bottomleft */}
                <Box
                    sx={{
                        width: "17ch",
                        padding: 1,
                    }}
                >
                    <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                    IST-Kosten
                    </Typography>
                    <Typography sx={{ fontSize: 13 }}>
                    {buildingHeatingSystemsState[buildingHeatingSystemsStateTab]
                                    .modernization_costs.user_input
                            ? buildingHeatingSystemsState[buildingHeatingSystemsStateTab]
                                    .modernization_costs.user_input.cost_heating_systems.display +
                                "€"
                            : "N/V"}
                    </Typography>
                </Box>
                <Divider orientation="vertical" flexItem />
                {/* Bottomright */}
                <Box
                    sx={{
                        width: "17ch",
                        padding: 1,
                    }}
                >
                    <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                        IST-Kosten je m²
                    </Typography>
                    <Typography sx={{ fontSize: 13 }}>
                    {buildingHeatingSystemsState[buildingHeatingSystemsStateTab]
                                    .modernization_costs.user_input
                            ? buildingHeatingSystemsState[buildingHeatingSystemsStateTab]
                                    .modernization_costs.user_input.cost_heating_systems_per_sqm.display +
                                "€"
                            : "N/V"}
                    </Typography>
                </Box>
            </Grid>
        </Box>
        </>
    )};