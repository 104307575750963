import { Route, Routes, Navigate } from "react-router-dom";
import Login from "./pages/Login/Login";
import Main from "./pages/Main/Main";
import PasswordReset from "./pages/Login/PasswordReset";
import { useContext } from "react";
import AuthContext from "./contexts/AuthContext";
import { ColorModeContext, useMode } from "./themes/theme";
import { ToastProvider } from "./contexts/ToastContext";
import { CssBaseline, ThemeProvider } from "@mui/material";
import NotFound from "./pages/Error/NotFound";
import Logout from "./pages/Logout/Logout";
import { QueryClient, QueryClientProvider } from "react-query";

/**
 * App component serving as the root of the React application.
 *
 * This component sets up the application's routing, theming, and global context providers.
 * It uses React Router for navigation and Material-UI for theming and styling. The application's
 * authentication state is managed using AuthContext, and based on this state, it redirects users
 * either to the login page or the dashboard. It also includes a ToastProvider for displaying
 * notifications and a ColorModeContext.Provider for managing theme color mode.
 *
 */
export default function App() {
    const { authenticated } = useContext(AuthContext);
    const [theme, colorMode] = useMode();
    const queryClient = new QueryClient();
    /*
    TODO: the query client should be helpful in managing already queried data and caching it, if this is not needed, it can be removed
    */
    return (
        <div>
            <ColorModeContext.Provider value={colorMode}>
                <QueryClientProvider client={queryClient}>
                    {" "}
                    <ThemeProvider theme={theme}>
                        <ToastProvider>
                            <CssBaseline />
                            <Routes>
                                <Route path="/">
                                    <Route
                                        index
                                        element={authenticated ? <Navigate to="/app/dashboard" /> : <Login />}
                                    />
                                    <Route path="app/*" element={<Main />} />
                                    <Route path="reset-password/*" element={<PasswordReset />} />
                                    <Route
                                        path="login"
                                        element={authenticated ? <Navigate to="/app/dashboard" /> : <Login />}
                                    />
                                    <Route path="logout" element={<Logout />} />
                                    <Route path="*" element={<NotFound />} />
                                </Route>
                            </Routes>
                        </ToastProvider>
                    </ThemeProvider>
                </QueryClientProvider>
            </ColorModeContext.Provider>
        </div>
    );
}
