/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback, useContext } from "react";
import { useImmer } from "use-immer";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    MenuItem,
    Box,
    Select,
    InputLabel,
    FormControl,
    Autocomplete,
    Fab,
    Modal,
    Paper,
    Stack,
    Grid,
    Divider,
    Button,
    ToggleButton,
} from "@mui/material";

// For YearPicker
import { validateConstructionYear } from "../../components/ValidationFunctions";

import { styled } from "@mui/material/styles";
import api_client from "../../services/http-common";
import { useParams } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import SaveIcon from "@mui/icons-material/Save";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import {
    FloatInput,
    IntegerInput,
    ValidatedTextField,
    BasicTextField,
    BasicSelectField,
    YearPicker,
} from "../../components/InputFields";
import { cleanNumbersInJson } from "../../utils/formatting";
import { createGermanNumberString } from "../../utils/formatting";
import DeleteIcon from "@mui/icons-material/Delete";
import ToastContext from "../../contexts/ToastContext";
import { BuildingPvAccordion } from "./BuildingPv";
import { UpdateTimeStampProvider } from "./UpdateTimestampContext";
import { BuildingConsumptionsAccordion } from "./BuildingConsumptions";
import { BuildingConditionsAccordion } from "./BuildingConditions/BuildingConditions";
import { BuildingEnergyCertificateAccordion } from "./BuildingEnergyCertificate";
import { EnergyEfficiencyClassesProvider } from "./EfficiencyClassesContext";
import { BuildingResultsAccordion } from "./BuildingResults";
import { BuildingHeatingSystemsAccordion } from "./BuildingHeatingSystems";
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal";
import { useNavigate } from "react-router-dom";
import { ImageUploadDropzone } from "../../components/FileUploadDropzone";
import { resizeFile } from "../../utils/imageresizing";
import { CustomTooltip } from "../../components/CustomTooltip";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import PushPinIcon from "@mui/icons-material/PushPin";
import { SelectLocationMap } from "../../components/Maps/SelectLocationMap";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { useBuildingData } from "../../components/FetchData";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "left",
    color: theme.palette.text.primary,
}));

const buildingTypes = [
    { name: "Einfamilienhaus", value: "Einfamilienhaus" },
    { name: "Reihenhaus und Doppelhaus", value: "Reihenhaus und Doppelhaus" },
    { name: "Kleines Mehrfamilienhaus (<13 WE)", value: "Kleines Mehrfamilienhaus" },
    { name: "Großes Mehrfamilienhaus (>=13 WE)", value: "Großes Mehrfamilienhaus" },
];

/**
 * Component for displaying detailed information about a selected building.
 *
 * This component renders a series of accordions that provide detailed views of different aspects of a building,
 * such as results, data, conditions, consumption, and photovoltaic (PV) information. It includes a building
 * selection component at the top, allowing users to choose a building to view details for. The component
 * conditionally renders the detailed accordions based on whether a building is selected.
 *
 * Returns:
 *   ReactElement: A component that displays detailed information about a building in accordion format.
 */
const BuildingDetail = () => {
    const consumptionTypes = [
        { name: "Raumwärme + Warmwasser", value: "RWWW" },
        { name: "Raumwärme", value: "RW" },
        { name: "Warmwasser", value: "WW" },
    ];
    const [currentBuilding, setCurrentBuilding] = useState({
        id: -1,
        address: "---",
    });
    const [currentBuildingIdx, setCurrentBuildingIdx] = useState(null); // index of current building in buildings array
    const [buildings, setBuildings] = useState([]);

    // -- SHARED DATA -- //
    // -------------------------- //
    // - list of energy sources - //
    const [energySources, setEnergySources] = useState([]);
    // fetch energy sources from backend
    const getEnergySources = () => {
        return api_client.get(`/energy-sources/`, {});
    };

    const fetchEnergySources = () => {
        getEnergySources().then((response) => {
            setEnergySources(response.data.energy_sources);
        });
    };

    // -------------------------- //
    // - heating system types - //
    const [heatingSystemTypes, setHeatingSystemTypes] = useState([]);
    // fetch energy sources from backend
    const getHeatingSystemTypes = () => {
        return api_client.get(`/heating-system-types/`, {});
    };

    const fetchHeatingSystemTypes = () => {
        getHeatingSystemTypes().then((response) => {
            setHeatingSystemTypes(response.data.data);
        });
    };
    // -------------------------- //
    // - emission models - //
    const [emissionModels, setEmissionModels] = useState([]);
    // fetch energy sources from backend
    const getEmissionModels = () => {
        return api_client.get(`/emission-models/`, {});
    };

    const fetchEmissionModels = () => {
        getEmissionModels().then((response) => {
            setEmissionModels(response.data.data);
        });
    };

    useEffect(() => {
        fetchEnergySources();
        fetchHeatingSystemTypes();
        fetchEmissionModels();
    }, []);

    const [energyCertificateValue, setEnergyCertificateValue] = useState(null); // value given in the energycertificate weighted and related to the living space

    // -- ACTUAL RENDER INFO -- //
    return (
        <EnergyEfficiencyClassesProvider>
            <UpdateTimeStampProvider>
                <BuildingSelection
                    setCurrentBuilding={setCurrentBuilding}
                    setCurrentBuildingIdx={setCurrentBuildingIdx}
                    currentBuilding={currentBuilding}
                    currentBuildingIdx={currentBuildingIdx}
                    buildings={buildings}
                    setBuildings={setBuildings}
                />
                {buildings.length > 1 && !(currentBuilding.id === -1) ? (
                    <>
                        <Box sx={{ position: "absolute", top: "10ch" }}>
                            <div>
                                {/* 1st accordion -- building results */}
                                <BuildingResultsAccordion
                                    currentBuilding={currentBuilding}
                                    energyCertificateValue={energyCertificateValue}
                                />
                            </div>

                            <div>
                                {/* 2nd accordion -- building data*/}
                                <BuildingDetailsAccordion currentBuilding={currentBuilding} />
                            </div>
                            <div>
                                {/* 3rd accordion -- building condition*/}
                                <BuildingConditionsAccordion currentBuilding={currentBuilding} />
                            </div>
                            <div>
                                {/* 4rd accordion -- building heating systems */}
                                <BuildingHeatingSystemsAccordion
                                    currentBuilding={currentBuilding}
                                    buildings={buildings}
                                    emissionModels={emissionModels}
                                    heatingSystemTypes={heatingSystemTypes}
                                    consumptionTypes={consumptionTypes}
                                    energySources={energySources}
                                />
                            </div>

                            <div>
                                {/* 5th accordion -- building consumption*/}
                                <BuildingConsumptionsAccordion
                                    currentBuilding={currentBuilding}
                                    energySources={energySources}
                                    consumptionTypes={consumptionTypes}
                                    heatingSystemTypes={heatingSystemTypes}
                                    emissionModels={emissionModels}
                                />
                            </div>
                            <div>
                                {/* 6th accordion -- building energy certificate*/}
                                <BuildingEnergyCertificateAccordion
                                    currentBuilding={currentBuilding}
                                    buildings={buildings}
                                    setEnergyCertificateValue={setEnergyCertificateValue}
                                />
                            </div>

                            <div>
                                {/* 7th accordion -- building pv*/}
                                <BuildingPvAccordion currentBuilding={currentBuilding} />
                            </div>
                        </Box>
                    </>
                ) : (
                    <></>
                )}
            </UpdateTimeStampProvider>
        </EnergyEfficiencyClassesProvider>
    );
};

export default BuildingDetail;

/**
 * Component for displaying and managing the core details of a building in an accordion format.
 *
 * This component allows users to view and edit basic information about a building, such as address,
 * postal code, city, construction year, building type, and additional notes. It provides an edit mode
 * for updating these details and integrates with backend services for data fetching and saving.
 *
 * Props:
 *   currentBuilding (Object): The building object for which the details are being managed.
 *
 * Returns:
 *   ReactElement: A component that renders an accordion with building core details and editing capabilities.
 */
const BuildingDetailsAccordion = ({ currentBuilding }) => {
    // -- CONSTANTS -- //
    const emptyBuildingDetails = {
        id: "none",
        ext_id: "",
        address: "",
        postal_code: "",
        city: "",
        construction_year: "",
        building_type_long: "",
        note: "",
        longitude: { display: "", real: 0 },
        latitude: { display: "", real: 0 },
    };

    const { addToastMessage } = useContext(ToastContext);

    // -- STATES AND SETUP -- //
    const [editModeBuildingDetails, setEditModeBuildingDetails] = useState(false);

    // Error-State
    const [errorBuildingDetails, setErrorBuildingDetails] = useImmer({});

    // current building details data
    const { data: buildingData, isLoading, isError: isErrorBuildingData } = useBuildingData(currentBuilding);
    const [buildingDetails, setBuildingDetails] = useImmer(emptyBuildingDetails);

    // flags for loading spinners when fetching data
    const [fetchingBuildingDetailsData, setFetchingBuildingDetailsData] = useState(true);

    // handle functions for delete confirmation modal image deletion
    const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false);
    const handleOpenDeleteConfirmationModal = () => setDeleteConfirmationModalOpen(true);
    const handleCloseDeleteConfirmationModal = () => setDeleteConfirmationModalOpen(false);

    // selectLocationMap Modal states
    const [selectLocationMapModalOpen, setSelectLocationMapModalOpen] = useState(false);

    // -- FUNCTIONS -- //
    // // fetch building information from backend
    const resetBuildingData = (() => {
        setEditModeBuildingDetails(false);
        // assign local states
        setBuildingDetails((details) => {
            details.id = buildingData.id;
            details.ext_id = buildingData.ext_id;
            details.address = buildingData.address;
            details.postal_code = buildingData.postal_code;
            details.city = buildingData.city;
            details.building_type_long = buildingData.building_type_long;
            details.construction_year = buildingData.construction_year;
            details.note = buildingData.note;
            details.image_url = buildingData.image_url;
            details.longitude = {
                display: buildingData.longitude ? createGermanNumberString(buildingData.longitude, false, 6) : "",
                real: buildingData.longitude,
            };
            details.latitude = {
                display: buildingData.latitude ? createGermanNumberString(buildingData.latitude, false, 6) : "",
                real: buildingData.latitude,
            };
        });
    });

    useEffect(() => {
        if (buildingData) {
            resetBuildingData();
            setFetchingBuildingDetailsData(false);
        }
    }, [buildingData]);

    useEffect(() => {
        if (isErrorBuildingData) {
            addToastMessage("Fehler beim Laden der Gebäudestammdaten.", "error");
            setFetchingBuildingDetailsData(false);
        }
    }, [isErrorBuildingData]);

    useEffect(() => {
        if (isLoading) {
            setFetchingBuildingDetailsData(true);
        } else {
            setFetchingBuildingDetailsData(false);
        }
    }, [isLoading]);

    // handle the toggling of the edit modes
    const handleEditMode = () => {
        if (editModeBuildingDetails === true) {
            resetBuildingData();
            setEditModeBuildingDetails(false);
        } else {
            setEditModeBuildingDetails(true);
        }
        setErrorBuildingDetails({});
    };

    // handle the saving after editing
    const handleSave = async () => {
        setEditModeBuildingDetails(false);
        setFetchingBuildingDetailsData(true);
        // post building-details to backend
        api_client
            .post(`/buildings/${currentBuilding.id}/`, cleanNumbersInJson(buildingDetails))
            .then((response) => {
                if (response.status === 200) {
                    addToastMessage("Gebäudestammdaten erfolgreich gespeichert.", "success");
                    const data = response.data.building;

                    // assign local states
                    setBuildingDetails((details) => {
                        details.id = data.id;
                        details.ext_id = data.ext_id;
                        details.address = data.address;
                        details.postal_code = data.postal_code;
                        details.city = data.city;
                        details.building_type_long = data.building_type_long;
                        details.construction_year = data.construction_year;
                        details.note = data.note;
                        details.image_url = data.image_url;
                        details.longitude = {
                            display: data.longitude ? createGermanNumberString(data.longitude, false, 6) : "",
                            real: data.longitude,
                        };
                        details.latitude = {
                            display: data.latitude ? createGermanNumberString(data.latitude, false, 6) : "",
                            real: data.latitude,
                        };
                    });
                    setFetchingBuildingDetailsData(false);
                } else {
                    addToastMessage("Gebäudestammdaten konnten nicht gespeichert werden.", "warning");
                    setFetchingBuildingDetailsData(false);
                }
            })
            .catch((error) => {
                addToastMessage("Fehler beim Speichern der Gebäudestammdaten.", "error");
                setFetchingBuildingDetailsData(false);
            });
    };

    const uploadImage = async (acceptedFiles) => {
        setFetchingBuildingDetailsData(true);

        // resize image
        const file = acceptedFiles[0];
        const resizedImage = await resizeFile(file);

        // send file to backend
        const formData = new FormData();
        formData.append("file", resizedImage);

        await api_client
            .post(`/buildings/${currentBuilding.id}/upload-image/`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((response) => {
                setBuildingDetails((details) => {
                    details.image_url = response.data.data.building.image_url;
                });
            });
        setFetchingBuildingDetailsData(false);
    };

    const deleteImage = async () => {
        handleCloseDeleteConfirmationModal();
        setFetchingBuildingDetailsData(true);
        await api_client.delete(`/buildings/${currentBuilding.id}/delete-image/`).then((response) => {
            setBuildingDetails((details) => {
                details.image_url = response.data.image_url;
            });
        });
        setFetchingBuildingDetailsData(false);
    };

    const SelectLocationMapModal = () => {
        return (
            <>
                <Modal
                    open={selectLocationMapModalOpen}
                    onClose={selectLocationMapModalOpen ? () => setSelectLocationMapModalOpen(false) : () => {}}
                    aria-labelledby="select-building-coordinates-modal"
                    data-testid="select-building-coordinates-modal"
                >
                    <Paper
                        sx={{
                            position: "absolute",
                            top: "40%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: "80vw",
                            height: "80vh",
                        }}
                    >
                        <Button
                            sx={{
                                position: "absolute",
                                top: "10px",
                                right: "5px",
                            }}
                            onClick={() => setSelectLocationMapModalOpen(false)}
                        >
                            <CloseIcon />
                        </Button>
                        <SelectLocationMap
                            center={
                                buildingDetails.latitude.real && buildingDetails.longitude.real
                                    ? { lat: buildingDetails.latitude.real, lng: buildingDetails.longitude.real }
                                    : null
                            }
                            width="78vw"
                            height="80vh"
                            maxWidth="80vw"
                            maxHeight="80vh"
                            setCoordinates={(coords) => {
                                if (coords) {
                                    setBuildingDetails((details) => {
                                        details.latitude.real = coords.lat;
                                        details.latitude.display = createGermanNumberString(coords.lat, false, 6);
                                        details.longitude.real = coords.lng;
                                        details.longitude.display = createGermanNumberString(coords.lng, false, 6);
                                    });
                                }
                                setSelectLocationMapModalOpen(false);
                            }}
                        />
                    </Paper>
                </Modal>
            </>
        );
    };

    return (
        <>
            <SelectLocationMapModal />
            <Box sx={{ mt: 3 }}>
                <Accordion sx={{ width: "160ch" }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <PlaceOutlinedIcon sx={{ fontSize: 30 }} />{" "}
                        <Typography
                            sx={{
                                ml: 2,
                                fontSize: 20,
                                fontWeight: "bold",
                            }}
                        >
                            Gebäudestammdaten
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                        }}
                    >
                        <Divider sx={{ mb: 2, mt: -2, width: "100%" }} />

                        {fetchingBuildingDetailsData && LoadingSpinner}
                        <Box sx={{ ml: 0, mb: 1 }}>
                            <div
                                sx={{
                                    marginLeft: "auto",
                                    display: "flex",
                                    flexDirection: "row",
                                }}
                            >
                                <Fab
                                    variant="extended"
                                    size="medium"
                                    color={editModeBuildingDetails ? "default" : "secondary"}
                                    onClick={() => {
                                        handleEditMode();
                                    }}
                                    sx={{ marginRight: "1rem", zIndex: 0, boxShadow: 1, borderRadius: 1 }}
                                    data-testid="building-details-edit-button"
                                >
                                    {editModeBuildingDetails ? <></> : <EditIcon sx={{ mr: 1 }} />}
                                    {editModeBuildingDetails ? "Abbrechen" : "Bearbeiten"}
                                </Fab>
                                {editModeBuildingDetails && (
                                    <Fab
                                        disabled={
                                            (Object.keys(errorBuildingDetails).length > 0 &&
                                                !Object.values(errorBuildingDetails).every((item) => item === false)) ||
                                            !(
                                                buildingDetails.address.length > 4 &&
                                                buildingDetails.postal_code &&
                                                buildingDetails.city.length > 2 &&
                                                buildingDetails.construction_year >= 1800 &&
                                                buildingDetails.building_type_long.length > 2
                                            )
                                        }
                                        variant="extended"
                                        size="medium"
                                        color={editModeBuildingDetails ? "secondary" : "default"}
                                        onClick={() => handleSave()}
                                        sx={{ marginRight: "1rem", zIndex: 0, boxShadow: 1, borderRadius: 1 }}
                                        data-testid="building-details-save-button"
                                    >
                                        <SaveIcon sx={{ mr: 1 }} />
                                        Speichern
                                    </Fab>
                                )}
                            </div>
                        </Box>
                        <Grid container spacing={2}>
                            <Box
                                sx={{
                                    mt: 2,
                                    color: "primary.main",
                                    borderRadius: 2,
                                }}
                                component="form"
                                noValidate
                                autoComplete="off"
                                onKeyUp={(e) => {
                                    if (e.key === "Enter" && editModeBuildingDetails) {
                                        if (
                                            !(
                                                (Object.keys(errorBuildingDetails).length > 0 &&
                                                    !Object.values(errorBuildingDetails).every(
                                                        (item) => item === false
                                                    )) ||
                                                !(
                                                    buildingDetails.address.length > 4 &&
                                                    buildingDetails.postal_code &&
                                                    buildingDetails.city.length > 2 &&
                                                    buildingDetails.construction_year.getFullYear() >= 1800 &&
                                                    buildingDetails.building_type_long.length > 2
                                                )
                                            )
                                        ) {
                                            handleSave();
                                        }
                                    }
                                }}
                            >
                                <div
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "0.5rem",
                                        mt: 2,
                                    }}
                                >
                                    <Grid sx={{ width: "48ch" }} container>
                                        <BasicTextField
                                            // variant={editModeBuildingDetails ? "outlined" : "filled"}
                                            label="Straße und Hausnummer"
                                            size="small"
                                            value={buildingDetails.address}
                                            onChange={(e) =>
                                                setBuildingDetails((details) => {
                                                    details.address = e.target.value;
                                                })
                                            }
                                            disabled={!editModeBuildingDetails}
                                            sx={{ ml: 2, mt: 1, mr: 1, width: "29ch" }}
                                            data-testid="building-details-address-input"
                                        />
                                        <BasicTextField
                                            // variant={editModeBuildingDetails ? "outlined" : "filled"}
                                            label="ID (optional)"
                                            size="small"
                                            value={buildingDetails.ext_id}
                                            onChange={(e) =>
                                                setBuildingDetails((details) => {
                                                    details.ext_id = e.target.value;
                                                })
                                            }
                                            disabled={!editModeBuildingDetails}
                                            sx={{ mt: 1, mr: 1, width: "13ch" }}
                                            data-testid="building-details-ext-id-input"
                                        />
                                        <ValidatedTextField
                                            // variant={editModeBuildingDetails ? "outlined" : "filled"}
                                            label="PLZ"
                                            size="small"
                                            value={buildingDetails.postal_code}
                                            onChange={(e) =>
                                                setBuildingDetails((details) => {
                                                    details.postal_code = e.target.value;
                                                })
                                            }
                                            validate={(val) => {
                                                if (
                                                    val.length > 5 ||
                                                    val.length < 4 ||
                                                    !Number.isInteger(Number(val))
                                                ) {
                                                    return false;
                                                } else {
                                                    return true;
                                                }
                                            }}
                                            error={!!errorBuildingDetails.postal_code}
                                            disabled={!editModeBuildingDetails}
                                            sx={{
                                                ml: 2,
                                                mt: 1,
                                                mr: 1,
                                                width: "12ch",
                                            }}
                                            data-testid="building-details-postal-code-input"
                                        />
                                        <BasicTextField
                                            // variant={editModeBuildingDetails ? "outlined" : "filled"}
                                            label="Ort"
                                            size="small"
                                            value={buildingDetails.city}
                                            onChange={(e) =>
                                                setBuildingDetails((details) => {
                                                    details.city = e.target.value;
                                                })
                                            }
                                            disabled={!editModeBuildingDetails}
                                            sx={{
                                                mt: 1,
                                                mr: 1,
                                                width: "30ch",
                                            }}
                                            data-testid="building-details-city-input"
                                        />
                                        <YearPicker
                                            name="construction_year"
                                            key="construction_year"
                                            label="Baujahr"
                                            size="small"
                                            variant="filled"
                                            minYear={1800}
                                            maxYear={2050}
                                            value={buildingDetails.construction_year}
                                            onChange={(val) =>
                                                setBuildingDetails((details) => {
                                                    details.construction_year = val.target.value;
                                                })
                                            }
                                            setError={(val) => {
                                                setErrorBuildingDetails((err) => {
                                                    err.construction_year = val.target.value;
                                                });
                                            }}
                                            disabled={!editModeBuildingDetails}
                                            //onBlur={handleAddBuildingInputBlur}
                                            sx={{ ml: 2, mt: 1, mr: 1, width: "12ch" }}
                                            //error={!!formAddBuildingErrors.construction_year}
                                            data-testid="building-details-construction-year-input"
                                            onBlur={(val) => {
                                                validateConstructionYear(val);
                                            }}
                                        />

                                        <BasicSelectField
                                            FormControlProps={{ sx: { mt: 1, mr: 1 } }}
                                            InputLabelProps={{ disabled: !editModeBuildingDetails }}
                                            label="Gebäudetyp"
                                            size="small"
                                            value={buildingDetails.building_type_long}
                                            onChange={(e) =>
                                                setBuildingDetails((details) => {
                                                    details.building_type_long = e.target.value;
                                                })
                                            }
                                            disabled={!editModeBuildingDetails}
                                            sx={{ width: "30ch" }}
                                            data-testid="building-details-building-type-select"
                                            menuItems={buildingTypes}
                                        />
                                        <BasicTextField
                                            // variant={editModeBuildingDetails ? "outlined" : "filled"}
                                            label="Anmerkung"
                                            value={buildingDetails.note}
                                            onChange={(e) =>
                                                setBuildingDetails((details) => {
                                                    details.note = e.target.value;
                                                })
                                            }
                                            disabled={!editModeBuildingDetails}
                                            multiline
                                            rows={2}
                                            sx={{ ml: 2, mt: 1, mr: 1, width: "43ch" }}
                                            data-testid="building-details-note-input"
                                        />
                                    </Grid>
                                </div>
                            </Box>
                            <Box sx={{ ml: 2, mt: 3, mr: 1, width: "24ch" }}>
                                <Typography variant="h8" sx={{ color: "text.secondary" }}>
                                    Gebäudekoordinaten
                                    <CustomTooltip
                                        title="Die Gebäudekoordinaten werden in der Regel automatisch gesetzt. Falls die Koordinaten nicht korrekt sind oder das automatische Ermitteln fehlgeschlagen ist, können Sie diese hier manuell anpassen."
                                        placement="right"
                                    >
                                        <InfoIcon sx={{ mb: "-7px", ml: 1 }} color="neutral" />
                                    </CustomTooltip>
                                </Typography>
                                <Box sx={{ position: "relative" }}>
                                    <FloatInput
                                        thousandsSeparator={false}
                                        decimalPlaces={6}
                                        // variant={editModeBuildingDetails ? "outlined" : "filled"}
                                        label="Längengrad"
                                        size="small"
                                        value={buildingDetails.longitude}
                                        onChange={(val) =>
                                            setBuildingDetails((details) => {
                                                details.longitude = val;
                                            })
                                        }
                                        disabled={!editModeBuildingDetails}
                                        sx={{ mt: 1, mr: 1, width: "21.5ch" }}
                                        validate={(val) => {
                                            return true;
                                        }}
                                        error={errorBuildingDetails.longitude}
                                        setError={(val) => {
                                            setErrorBuildingDetails((err) => {
                                                err.longitude = val;
                                            });
                                        }}
                                        data-testid="building-details-longitude-input"
                                    />
                                    <FloatInput
                                        thousandsSeparator={false}
                                        decimalPlaces={6}
                                        // variant={editModeBuildingDetails ? "outlined" : "filled"}
                                        label="Breitengrad"
                                        size="small"
                                        value={buildingDetails.latitude}
                                        onChange={(val) =>
                                            setBuildingDetails((details) => {
                                                details.latitude = val;
                                            })
                                        }
                                        disabled={!editModeBuildingDetails}
                                        sx={{ mt: 1, mr: 1, width: "21.5ch" }}
                                        validate={(val) => {
                                            return true;
                                        }}
                                        error={errorBuildingDetails.latitude}
                                        setError={(val) => {
                                            setErrorBuildingDetails((err) => {
                                                err.latitude = val;
                                            });
                                        }}
                                        data-testid="building-details-latitude-input"
                                    />
                                    <Fab
                                        variant="extended"
                                        size="small"
                                        onClick={() => setSelectLocationMapModalOpen(true)}
                                        sx={{ mt: 1, zIndex: 0, boxShadow: 1, borderRadius: 1 }}
                                        disabled={!editModeBuildingDetails}
                                    >
                                        <MapOutlinedIcon sx={{ mr: 1 }} />
                                        Koordinaten wählen
                                    </Fab>
                                </Box>
                            </Box>
                            <Box sx={{ ml: 2, mt: 3, mr: 1, width: "43ch" }}>
                                <Typography variant="h8" sx={{ color: "text.secondary" }}>
                                    Gebäudebild
                                </Typography>
                                {buildingDetails.image_url ? (
                                    <Box sx={{ position: "relative" }}>
                                        {/* Building image display from buildingDetails.image_url */}
                                        <img src={buildingDetails.image_url} alt="building" loading="lazy" />
                                        <Fab
                                            variant="circular"
                                            size="small"
                                            onClick={handleOpenDeleteConfirmationModal}
                                            sx={{ ml: -5.5, mt: 0.5, opacity: 0.8, position: "absolute" }}
                                        >
                                            <DeleteIcon />
                                        </Fab>
                                    </Box>
                                ) : (
                                    <ImageUploadDropzone onFileUpload={uploadImage} />
                                )}
                                <DeleteConfirmationModal
                                    deleteConfirmationModalOpen={deleteConfirmationModalOpen}
                                    handleCloseDeleteConfirmationModal={handleCloseDeleteConfirmationModal}
                                    confirmationText={
                                        "Sind Sie sicher, dass Sie " +
                                        'das aktuelle Bild zum Gebäude "' +
                                        currentBuilding?.address +
                                        '"' +
                                        " löschen möchten?"
                                    }
                                    deletionTargetFunction={deleteImage}
                                    data-testid="delete-confirmation-modal-building-image"
                                />
                            </Box>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Box>
        </>
    );
};

/**
 * Component for selecting, adding, or deleting a building from a list of buildings.
 *
 * This component provides a dropdown for selecting a building, buttons for adding a new building or deleting
 * an existing one, and modals for confirming these actions. It integrates with backend services for fetching,
 * posting, and deleting building data. It also handles navigation and toast messages for user feedback.
 *
 * Props:
 *   buildings (Array): Array of building objects to choose from.
 *   setBuildings (Function): Function to update the buildings array.
 *   currentBuilding (Object): The currently selected building object.
 *   setCurrentBuilding (Function): Function to update the currently selected building.
 *
 * Returns:
 *   ReactElement: A component that provides UI for building selection, addition, and deletion.
 */
const BuildingSelection = ({
    buildings,
    setBuildings,
    currentBuilding,
    currentBuildingIdx,
    setCurrentBuilding,
    setCurrentBuildingIdx,
}) => {
    // -- CONTEXT -- //
    const navigate = useNavigate();
    const { addToastMessage } = useContext(ToastContext);

    // Form Validation
    const [formAddBuildingErrors, setFormAddBuildingErrors] = useState({});

    // -- CONSTANTS -- //
    const emptyBuilding = { id: -1, address: "---" };
    const emptyBuildingDetails = {
        id: "none",
        ext_id: "",
        address: "",
        postal_code: "",
        city: "",
        construction_year: null,
        building_type_long: "",
        note: "",
    };

    // new building details data (used in AddBuildingModal)
    const [newBuildingDetails, setNewBuildingDetails] = useImmer(emptyBuildingDetails);
    const [errorNewBuildingDetails, setErrorNewBuildingDetails] = useImmer({});

    const [loadingBuildings, setLoadingBuildings] = useState(false);

    // get the building entry from our buildings array via its id
    const getBuildingFromId = useCallback((id, buildings) => {
        let building = {
            id: -1,
            address: "---",
        };
        buildings.forEach((entry) => {
            if (Number(entry.id) === Number(id)) {
                building = entry;
                return;
            }
        });
        return building;
    }, []);

    // the current building (with id and address)
    const pCurrentBuildingId = useParams().pCurrentBuildingId;

    useEffect(() => {
        if (pCurrentBuildingId !== "none") {
            setCurrentBuilding(getBuildingFromId(pCurrentBuildingId, buildings));
            setCurrentBuildingIdx(buildings.indexOf(getBuildingFromId(pCurrentBuildingId, buildings)));
        } else {
            setCurrentBuilding(emptyBuilding);
            setCurrentBuildingIdx(-1);
        }
    }, [buildings]);

    // -- API CALLS -- //
    // get simple buildings array from backend
    function getBuildings() {
        return api_client.get(`/buildings/`, {});
    }

    const fetchBuildings = () => {
        setLoadingBuildings(true);
        getBuildings().then((response) => {
            var buildingsArr = [emptyBuilding];
            buildingsArr = buildingsArr.concat(response.data.buildings);
            setBuildings(buildingsArr);
            setLoadingBuildings(false);
        });
    };

    useEffect(() => {
        fetchBuildings();
    }, []);

    // handle functions for addNewBuilding
    const [addNewBuildingModalOpen, setAddNewBuildingModalOpen] = useState(false);
    const handleOpenAddNewBuilding = () => setAddNewBuildingModalOpen(true);
    const handleCloseAddNewBuilding = () => setAddNewBuildingModalOpen(false);

    // handle functions for delete confirmation modal
    const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false);
    const handleOpenDeleteConfirmationModal = () => setDeleteConfirmationModalOpen(true);
    const handleCloseDeleteConfirmationModal = () => setDeleteConfirmationModalOpen(false);

    // delete building
    const deleteBuilding = async () => {
        handleCloseDeleteConfirmationModal();
        const responseDeleteBuilding = await api_client.delete(`/buildings/${currentBuilding.id}/`, {});
        setCurrentBuilding(emptyBuilding);
        navigate("/app/buildingdetails/none");
        if ((await responseDeleteBuilding.status) === 200) {
            fetchBuildings();
            addToastMessage("Gebäude erfolgreich gelöscht.", "success", true, 6000, "toast-delete-building-success");
        } else {
            addToastMessage(
                "Beim Löschen des Gebäudes ist ein Fehler aufgetreten, bitte versuchen Sie es erneut.",
                "error",
                true,
                6000,
                "toast-delete-building-error"
            );
        }
    };

    const handleAddBuildingInputChange = (e) => {
        const { name, value } = e.target;
        setNewBuildingDetails((details) => {
            details[name] = value;
        });
    };
    const handleAddBuildingInputBlur = (e) => {
        const { name, value } = e.target;
        // Validate based on the field's name
        if (name === "address" && (!value || value.length <= 4)) {
            setFormAddBuildingErrors((errors) => ({ ...errors, address: true }));
        } else if (
            name === "postal_code" &&
            (!value || value.length < 4 || value.length > 5 || !Number.isInteger(Number(value)))
        ) {
            setFormAddBuildingErrors((errors) => ({
                ...errors,
                postal_code: "Die Postleitzahl scheint nicht korrekt zu sein.",
            }));
        } else if (name === "city" && (!value || value.length <= 2)) {
            setFormAddBuildingErrors((errors) => ({ ...errors, city: true }));
        } else if (name === "construction_year" && (!value || value < 1800 || value > 2050)) {
            setFormAddBuildingErrors((errors) => ({
                ...errors,
                construction_year: "Das Gebäudejahr muss zwischen 1800 und 2050 liegen.",
            }));
        } else if (name === "building_type_long" && (!value || value.length <= 2)) {
            setFormAddBuildingErrors((errors) => ({ ...errors, building_type_long: true }));
        } else {
            // If validation passed, remove error from error object if it exists
            setFormAddBuildingErrors((errors) => {
                const newErrors = { ...errors };
                delete newErrors[name];
                return newErrors;
            });
        }
    };

    // add new building
    const addNewBuilding = async () => {
        api_client
            .post(`/buildings/new/`, cleanNumbersInJson(newBuildingDetails))
            .then((response) => {
                // Reset Validation Errors from last time
                setFormAddBuildingErrors({});

                const responseNewBuildingData = response.data.building;
                handleCloseAddNewBuilding();
                setCurrentBuilding({ id: responseNewBuildingData.id, address: responseNewBuildingData.address });
                navigate("/app/buildingdetails/" + responseNewBuildingData.id);
                setBuildings([
                    ...buildings,
                    { id: responseNewBuildingData.id, address: responseNewBuildingData.address },
                ]);
                setNewBuildingDetails((details) => {
                    details.id = emptyBuildingDetails.id;
                    details.address = emptyBuildingDetails.address;
                    details.city = emptyBuildingDetails.city;
                    details.postal_code = emptyBuildingDetails.postal_code;
                    details.construction_year = emptyBuildingDetails.construction_year;
                    details.building_type_long = emptyBuildingDetails.building_type_long;
                    details.ext_id = emptyBuildingDetails.ext_id;
                    details.note = emptyBuildingDetails.note;
                });
                addToastMessage("Gebäude erfolgreich angelegt.", "success", true, 6000, "toast-add-building-success");
            })
            .catch((error) => {
                const data = error.response.data;
                // Set validation errors if they exists
                if (data.validation_errors) {
                    setFormAddBuildingErrors(data.validation_errors);
                }
                if (data.toast_msg && data.toast_tag) {
                    addToastMessage(data.toast_msg, "error", true, 6000, data.toast_tag);
                } else {
                    addToastMessage(
                        "Beim Anlegen des Gebäudes ist ein Fehler aufgetreten, bitte versuchen Sie es später erneut.",
                        "error",
                        true,
                        6000,
                        "toast-add-building-error"
                    );
                }
            });
    };

    // handle the selection of a building from the dropdown
    const handleSelect = (value) => {
        if (value) {
            setCurrentBuilding(value);
            setCurrentBuildingIdx(buildings.indexOf(value));
            navigate("/app/buildingdetails/" + value.id);
        }
    };

    // -- RENDER COMPONENTS -- //
    const AddBuildingModal = (
        <>
            <Modal
                open={addNewBuildingModalOpen}
                onClose={handleCloseAddNewBuilding}
                aria-labelledby="add-year-modal"
                onKeyUp={(e) => {
                    if (e.key === "Enter") {
                        if (Object.keys(formAddBuildingErrors).length === 0) {
                            addNewBuilding();
                        }
                    }
                }}
                data-testid="add-building-modal"
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 465,
                        bgcolor: "background.paper",
                        border: "2px solid #000",
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Button
                        sx={{
                            position: "absolute",
                            top: "10px",
                            right: "5px",
                        }}
                        onClick={() => handleCloseAddNewBuilding()}
                    >
                        <CloseIcon />
                    </Button>
                    <Typography id="modal-addbuilding-title" variant="h6" component="h2" sx={{ mb: 1 }}>
                        Geben Sie die Stammdaten des Gebäudes an.
                    </Typography>
                    <div
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "0.5rem",
                            mt: 2,
                        }}
                    >
                        <div>
                            <BasicTextField
                                name="address"
                                variant={"outlined"}
                                label="Straße und Hausnummer*"
                                size="small"
                                value={newBuildingDetails.address}
                                onChange={handleAddBuildingInputChange}
                                onBlur={handleAddBuildingInputBlur}
                                sx={{ mr: 1, mt: 1, mb: 1, width: "29ch" }}
                                data-testid="add-building-address-input"
                                error={!!formAddBuildingErrors.address}
                            />
                            <BasicTextField
                                name="ext_id"
                                key="ext_id"
                                variant={"outlined"}
                                label="ID (optional)"
                                size="small"
                                value={newBuildingDetails.ext_id}
                                onChange={handleAddBuildingInputChange}
                                onBlur={handleAddBuildingInputBlur}
                                sx={{ mt: 1, width: "13ch" }}
                                data-testid="add-building-ext-id-input"
                            />
                        </div>
                        <div>
                            <BasicTextField
                                name="postal_code"
                                key="postal_code"
                                variant="outlined"
                                label="PLZ*"
                                size="small"
                                value={newBuildingDetails.postal_code}
                                onChange={handleAddBuildingInputChange}
                                onBlur={handleAddBuildingInputBlur}
                                error={!!formAddBuildingErrors.postal_code}
                                sx={{
                                    mr: 1,
                                    mt: 1,
                                    mb: 1,
                                    width: "12ch",
                                }}
                                data-testid="add-building-postalcode-input"
                            />
                            <BasicTextField
                                name="city"
                                key="city"
                                variant={"outlined"}
                                label="Ort*"
                                size="small"
                                value={newBuildingDetails.city}
                                onChange={handleAddBuildingInputChange}
                                onBlur={handleAddBuildingInputBlur}
                                sx={{
                                    mt: 1,
                                    mb: 1,
                                    width: "30ch",
                                }}
                                data-testid="add-building-city-input"
                                error={!!formAddBuildingErrors.city}
                            />
                        </div>
                        <div>
                            <YearPicker
                                name="construction_year"
                                key="construction_year"
                                label="Baujahr*"
                                size="small"
                                minYear={1800}
                                maxYear={2050}
                                value={newBuildingDetails.construction_year}
                                onChange={handleAddBuildingInputChange}
                                onBlur={handleAddBuildingInputBlur}
                                sx={{ mr: 1, mt: 1, mb: 1, width: "12ch" }}
                                error={!!formAddBuildingErrors.construction_year}
                                data-testid="add-building-construction-year-input"
                            />

                            <FormControl sx={{ mt: 1, mb: 1 }} data-testid="add-building-building-type-select">
                                <InputLabel
                                    sx={{
                                        mt: newBuildingDetails.building_type_long ? 0 : -0.5,
                                    }}
                                >
                                    Gebäudetyp*
                                </InputLabel>
                                <Select
                                    name="building_type_long"
                                    key="building_type_long"
                                    variant={"outlined"}
                                    label="Gebäudetyp*"
                                    size="small"
                                    value={newBuildingDetails.building_type_long}
                                    onChange={handleAddBuildingInputChange}
                                    onBlur={handleAddBuildingInputBlur}
                                    sx={{ width: "30ch" }}
                                    error={!!formAddBuildingErrors.building_type_long}
                                >
                                    {buildingTypes.map((item) => (
                                        <MenuItem key={item.name} value={item.value}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div>
                            <BasicTextField
                                name="note"
                                key="note"
                                variant={"outlined"}
                                label="Anmerkung"
                                value={newBuildingDetails.note}
                                onChange={handleAddBuildingInputChange}
                                onBlur={handleAddBuildingInputBlur}
                                multiline
                                rows={2}
                                sx={{ mt: 1, mb: 1, width: "43ch" }}
                                data-testid="add-building-note-input"
                                error={!!formAddBuildingErrors.note}
                            />
                        </div>
                    </div>
                    <Fab
                        variant="extended"
                        size="medium"
                        disabled={
                            Object.keys(formAddBuildingErrors).length > 0 ||
                            newBuildingDetails.address.length === 0 ||
                            newBuildingDetails.postal_code.length === 0 ||
                            newBuildingDetails.city.length === 0 ||
                            newBuildingDetails.construction_year === null ||
                            newBuildingDetails.building_type_long.length === 0
                        }
                        onClick={(e) => addNewBuilding()}
                        color="secondary"
                        sx={{ mt: 0.8, ml: "20%", width: "60%", boxShadow: 1, borderRadius: 1 }}
                        data-testid="add-building-save-building-button"
                    >
                        Speichern
                    </Fab>
                </Box>
            </Modal>
        </>
    );

    const [headerFixed, setHeaderFixed] = useState(true);

    return (
        <div>
            <Stack
                width="160ch"
                sx={{
                    position: headerFixed ? "fixed" : "static",
                    // top: 0,
                    // left: "50%" - "80ch",
                    zIndex: 100,
                }}
            >
                <Item
                    sx={{
                        height: "10ch",
                        backgroundColor: headerFixed ? "#BFD5FF" : "#fff",
                        opacity: headerFixed ? 0.95 : 1,
                        mt: 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Grid container sx={{ mt: headerFixed ? 1 : 1 }}>
                        {/* building selection autocomplete basictextfield */}
                        <Autocomplete
                            key="building-selection"
                            size="small"
                            disablePortal
                            autoComplete
                            id="combo-box-select-building"
                            options={buildings}
                            getOptionLabel={(option) => option.address}
                            sx={{ width: "40ch", mt: 2, ml: 1, mb: 1 }}
                            renderInput={(params) => <BasicTextField {...params} label="Gebäudeauswahl" />}
                            value={currentBuilding}
                            onChange={(event, value) => {
                                handleSelect(value);
                            }}
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            data-testid="building-selection-autocomplete"
                            loading={loadingBuildings}
                            loadingText="Lade Gebäude..."
                        />
                        {/*  current building image */}
                        <Box
                            sx={{
                                height: "8ch",
                                width: "14ch",
                                mt: 0.2,
                                ml: 1,
                                justifyContent: "center",
                                alignItems: "center",
                                display: "flex",
                            }}
                        >
                            {currentBuilding.image_url ? (
                                <Box
                                    component="img"
                                    sx={{
                                        maxHeight: { xs: "7ch", md: "7ch" },
                                        maxWidth: { xs: "14ch", md: "14ch" },
                                        mt: 0.3,
                                    }}
                                    alt="Gebäude"
                                    src={currentBuilding.image_url}
                                />
                            ) : (
                                <Box
                                    sx={{
                                        height: "7ch",
                                        width: "14ch",
                                        backgroundColor: "#DDDDDD",
                                        opacity: 0.5,
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        border: "1.5px dashed grey", // Add dashed border
                                    }}
                                >
                                    Gebäudebild
                                </Box>
                            )}
                        </Box>

                        <Divider orientation="vertical" flexItem sx={{ ml: 1, mt: 0.6, height: 60 }} />

                        {/* Floating action buttons to switch to previous or next building */}
                        <Box width="42ch">
                            <Grid container>
                                {/* info of previous building */}
                                <Box
                                    width="20ch"
                                    height="10ch"
                                    sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                                >
                                    <Box width="18ch" sx={{ mt: -0.5, mr: -2 }}>
                                        <Typography variant="h6" sx={{ ml: 1, mb: 1, textAlign: "center" }}>
                                            Vorheriges
                                            <br />
                                            Gebäude
                                        </Typography>
                                    </Box>
                                    <Fab
                                        color="secondary"
                                        sx={{ ml: 1, mt: -1, boxShadow: 1, borderRadius: 5 }}
                                        disabled={
                                            buildings.length < 3 || currentBuildingIdx === 1 || !currentBuildingIdx
                                        } // if only one building is available (+empty dummy building), disable the button
                                        onClick={() => {
                                            const newBuildingIdx = currentBuildingIdx - 1;
                                            setCurrentBuildingIdx(newBuildingIdx);
                                            setCurrentBuilding(buildings[newBuildingIdx]);
                                            navigate("/app/buildingdetails/" + buildings[newBuildingIdx].id);
                                        }}
                                    >
                                        <NavigateBeforeIcon />
                                    </Fab>
                                </Box>

                                {/* button to navigate to next building */}
                                <Box
                                    width="20ch"
                                    height="10ch"
                                    sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                                >
                                    <Fab
                                        color="secondary"
                                        disabled={buildings.length < 3 || currentBuildingIdx === buildings.length - 1}
                                        sx={{ ml: 1, mt: -1, boxShadow: 1, borderRadius: 5 }}
                                        onClick={() => {
                                            const newBuildingIdx = currentBuildingIdx + 1;
                                            setCurrentBuildingIdx(newBuildingIdx);
                                            setCurrentBuilding(buildings[newBuildingIdx]);
                                            navigate("/app/buildingdetails/" + buildings[newBuildingIdx].id);
                                        }}
                                    >
                                        <NavigateNextIcon />
                                    </Fab>
                                    <Box width="18ch" sx={{ mt: -0.5, ml: -2 }}>
                                        <Typography variant="h6" sx={{ ml: 1, mb: 1, textAlign: "center" }}>
                                            Nächstes
                                            <br />
                                            Gebäude
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Box>
                        <Divider orientation="vertical" flexItem sx={{ ml: 1, mt: 0.6, height: 60 }} />
                        <Box>
                            <Fab
                                variant="extended"
                                size="small"
                                onClick={(e) => handleOpenAddNewBuilding()}
                                sx={{ ml: 2, mt: 2, boxShadow: 1, borderRadius: 1 }}
                                data-testid="add-building-button"
                            >
                                <AddIcon />
                                Gebäude hinzufügen
                            </Fab>
                            <Fab
                                variant="extended"
                                size="small"
                                disabled={!(buildings.length > 1 && !(currentBuilding?.id === -1))}
                                onClick={(e) => handleOpenDeleteConfirmationModal()}
                                sx={{ ml: 2, mt: 2, boxShadow: 1, borderRadius: 1 }}
                                data-testid="delete-building-button"
                            >
                                <DeleteIcon />
                                Gebäude löschen
                            </Fab>
                            {AddBuildingModal}
                            <DeleteConfirmationModal
                                deleteConfirmationModalOpen={deleteConfirmationModalOpen}
                                handleCloseDeleteConfirmationModal={handleCloseDeleteConfirmationModal}
                                confirmationText={
                                    "Sind Sie sicher, dass Sie " +
                                    'das Gebäude "' +
                                    currentBuilding?.address +
                                    '"' +
                                    " und alle zugehörigen Daten löschen möchten?"
                                }
                                deletionTargetFunction={deleteBuilding}
                                data-testid="delete-confirmation-modal"
                            />
                            <ToggleButton
                                value="check"
                                selected={headerFixed}
                                onChange={() => {
                                    setHeaderFixed(!headerFixed);
                                }}
                                sx={{
                                    justifyContent: "center",
                                    alignItems: "right",
                                    ml: 3,
                                    mt: 2,
                                    height: "4.5ch",
                                    width: "4.5ch",
                                }}
                            >
                                <PushPinIcon sx={{ transform: headerFixed ? "rotate(45deg)" : "" }} />
                            </ToggleButton>
                        </Box>
                    </Grid>
                </Item>
            </Stack>
        </div>
    );
};
