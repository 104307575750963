import { useEffect} from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../themes/theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import SettingsIcon from "@mui/icons-material/Settings";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { useContext } from "react";
import SidebarContext from "../contexts/SidebarContext";
import LogoutIcon from "@mui/icons-material/Logout";
import { useLocation } from 'react-router-dom';


const Item = ({ title, to, icon, selected, setSelected }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
        <MenuItem
            active={selected === title}
            style={{
                color: colors.grey[100],
            }}
            icon={icon}
        >
            <Typography>{title}</Typography>
            <Link to={to} />
        </MenuItem>
    );
};

/**
 * Renders a sidebar navigation menu with collapsible functionality.
 *
 * This component displays a sidebar with various navigation options. It includes a logo, user information,
 * and menu items for different sections of the application. The sidebar can be collapsed or expanded, and
 * it highlights the currently selected menu item. The appearance and behavior of the sidebar are customizable
 * through props and theme settings.
 *
 * @param {Object} props - The component props.
 * @param {string} props.username - The username to display in the sidebar.
 * @param {string} props.usergroup - The user group to display in the sidebar.
 * @returns {JSX.Element} A div containing the sidebar with navigation options.
 *
 * @example
 * <Sidebar username="JohnDoe" usergroup="Admin" />
 */
const Sidebar = ({ username, usergroup }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { isCollapsed, setIsCollapsed } = useContext(SidebarContext);
    const { isCollapsedFinished, setIsCollapsedFinished } = useContext(SidebarContext);
    const {selected, setSelected} = useContext(SidebarContext);

    const location = useLocation(); // Add this

    // Add this useEffect
    useEffect(() => {
    const path = location.pathname;
    const normalizedPath = path.startsWith('/app/') ? path.slice(4) : path;
      // Map paths to your exact sidebar item titles
    const pathToSidebarItem = {
        '/dashboard': 'Dashboard',
        '/portfolioanalysis': 'Bestandsverlauf',
        '/results': 'Gebäudeliste',
        '/settings': 'Einstellungen',
        '/faq': 'FAQ',
        '/logout': 'Logout'
      };
      
      // Special handling for building details since it has a parameter
      if (normalizedPath.startsWith('/buildingdetails/')) {
        setSelected('Gebäudedetails');
        return;
      }
  
      const currentSection = pathToSidebarItem[normalizedPath];
      if (currentSection) {
        setSelected(currentSection);
      }
    }, [location.pathname, setSelected]);
  

    return (
        <div style={{ display: "flex", height: "100% !important" }}>
            <Box
                sx={{
                    height: "98vh",
                    "& .pro-sidebar-inner": {
                        background: `${colors.primary[400]} !important`,
                    },
                    "& .pro-icon-wrapper": {
                        backgroundColor: "transparent !important",
                    },
                    "& .pro-inner-item": {
                        padding: "5px 35px 5px 20px !important",
                    },
                    "& .pro-inner-item:hover": {
                        color: `${colors.greenAccent[400]} !important`,
                    },
                    "& .pro-menu-item.active": {
                        color: `${colors.greenAccent[500]} !important`,
                        fontWeight: "bold",
                    },
                }}
            >
                <ProSidebar
                    collapsed={isCollapsed}
                    style={{ position: "fixed", width: isCollapsed ? "6ch" : "28ch" }}
                    data-testid="pro-sidebar"
                >
                    <Menu iconShape="square">
                        {/* LOGO AND MENU ICON */}
                        <MenuItem
                            onClick={() => {
                                setIsCollapsed(!isCollapsed);
                                setTimeout(() => {
                                    setIsCollapsedFinished(!isCollapsedFinished);
                                }, 700);
                            }}
                            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
                            style={{
                                margin: "10px 0 20px 0",
                                color: colors.grey[100],
                            }}
                        >
                            {!isCollapsed && (
                                <Box display="flex" justifyContent="space-between" alignItems="center" ml="15px">
                                    <Typography variant="h3" color={colors.grey[100]}>
                                        Navigation
                                    </Typography>
                                    <IconButton
                                        onClick={() => {
                                            setIsCollapsed(!isCollapsed);
                                            setTimeout(() => {
                                                setIsCollapsedFinished(!isCollapsedFinished);
                                            }, 700);
                                        }}
                                    >
                                        <MenuOutlinedIcon />
                                    </IconButton>
                                </Box>
                            )}
                        </MenuItem>

                        {!isCollapsed && (
                            <Box mb="25px">
                                <Box display="flex" justifyContent="center" alignItems="center"></Box>
                                <Box textAlign="center">
                                    <Typography
                                        variant="h2"
                                        color={colors.grey[100]}
                                        fontWeight="bold"
                                        sx={{ m: "10px 0 0 0" }}
                                    >
                                        <img src={"/EnergyARK_Full.png"} alt="EnergyARK Logo" width="230" height="42" />
                                    </Typography>
                                    <Typography variant="h5" color={colors.greenAccent[500]}>
                                        {username + " | " + usergroup}
                                    </Typography>
                                </Box>
                            </Box>
                        )}

                        <Box paddingLeft={isCollapsed ? undefined : "10%"}>
                            <Typography variant="h5" color={colors.grey[300]} sx={{ m: "15px 0 5px 10px" }}>
                                {isCollapsed ? "" : "Übersicht"}
                            </Typography>
                            <Item
                                title="Dashboard"
                                to="dashboard"
                                icon={<DashboardIcon />}
                                selected={selected}
                            />
                            <Item
                                title="Bestandsverlauf"
                                to="portfolioanalysis"
                                icon={<QueryStatsIcon />}
                                selected={selected}
                            />

                            <Typography variant="h5" color={colors.grey[300]} sx={{ m: "15px 0 5px 10px" }}>
                                {isCollapsed ? "" : "Daten"}
                            </Typography>
                            <Item
                                title="Gebäudedetails"
                                to="buildingdetails/none"
                                icon={<HomeOutlinedIcon />}
                                selected={selected}
                            />
                            <Item
                                title="Gebäudeliste"
                                to="results"
                                icon={<MapsHomeWorkIcon />}
                                selected={selected}
                            />
                            <Typography variant="h5" color={colors.grey[300]} sx={{ m: "15px 0 5px 10px" }}>
                                {isCollapsed ? "" : "Weiteres"}
                            </Typography>
                            <Item
                                title="Einstellungen"
                                to="settings"
                                icon={<SettingsIcon />}
                                selected={selected}
                            />
                            <Item
                                title="FAQ"
                                to="faq"
                                icon={<HelpOutlineOutlinedIcon />}
                                selected={selected}
                            />
                            <Item
                                title="Logout"
                                to="/logout"
                                icon={<LogoutIcon />}
                                selected={selected}
                            />
                        </Box>
                    </Menu>
            {isCollapsed ? <></> :
            <Box  sx={{height:"100%", display: "flex", justifyContent: 'center', alignItems: 'end', mb: "15%"}}>
                <img src={"/Logo_ohne_Hintergrund.png"} alt="AdvisoRE Logo" width="200" />
            </Box>
            }
                </ProSidebar>
                
            </Box>
            
        </div>
    );
};

export default Sidebar;
