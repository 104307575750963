import { useQuery } from "react-query";
import api_client from "../services/http-common";

/* Simon: Maybe(!) in the long run, transfer all the api calls to this file, so that the components can be cleaner 
          and more readable. Also has the benefit that whenever when multiple components need the same data the 
          performance is improved
*/
// Use the useBuildingData hook to fetch building data like so
// const { data:buildingData, isLoading:isLoadingBuildingData, isError:isErrorBuildingData} = useBuildingData(currentBuilding);

const fetchBuildingData = async (buildingId) => {
    const response = await api_client.get(`/buildings/${buildingId}/`);
    return response.data.building; // Return the entire building object
};

export const useBuildingData = (currentBuilding) => {
    const buildingId = currentBuilding?.id;
    return useQuery(
        ["buildingData", buildingId], // Unique query key
        () => fetchBuildingData(buildingId), // Fetch function
        {
            enabled: !!buildingId, // Run only if buildingId exists
        }
    );
};
