/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback, useContext} from "react";
import { useImmer } from "use-immer";
import { Typography, Box, Fab, Modal, Divider, Grid, Button, FormControl, RadioGroup, FormControlLabel,Radio, InputAdornment} from "@mui/material";
import api_client from "../../../services/http-common";
import { addDisplayNumbersInJson } from "../../../utils/formatting";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import { FloatInput } from "../../../components/InputFields";
import InsightsTwoToneIcon from "@mui/icons-material/InsightsTwoTone";
import ToastContext from "../../../contexts/ToastContext";
import ReportProblemTwoToneIcon from "@mui/icons-material/ReportProblemTwoTone";
import { CustomTooltip } from "../../../components/CustomTooltip";
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';


export const HeatingSystemPowerEstimationModal = ({ heatingSystemPowerEstimationModalOpen, setHeatingSystemPowerEstimationModalOpen, heatingSystemType, year, shareOfConsumptionRoomheating, currentBuilding, setHeatingSystemPower }) => {
    const { addToastMessage } = useContext(ToastContext);
    const [heatingSystemPowerEstimationError, setHeatingSystemPowerEstimationError] = useImmer({});
    const [fetchingConsumptionEstimation, setFetchingConsumptionEstimation] = useState(false);
    const [powerEstimationData, setPowerEstimationData] = useImmer({roomheatingDemand: {display: "0", real: 0}, shareOfConsumptionRoomheating: shareOfConsumptionRoomheating, year: year,  heatingSystemType: heatingSystemType});
    const [demandEstimationType, setDemandEstimationType] = useState("automatic");
    const [roomheatingDemandForecast, setRoomheatingDemandForecast] = useState(null);
    const [roomheatingDemandConsumption, setRoomheatingDemandConsumption] = useState(null);
    const [roomheatingDemandForecastYear, setRoomheatingDemandForecastYear] = useState(null);
    const [roomheatingDemandConsumptionYear, setRoomheatingDemandConsumptionYear] = useState(null);
    const [yearLabel, setYearLabel] = useState(null);

    const demandEstimationRadioGroupHandleChange = (event) => {
        setDemandEstimationType(event.target.value);
        if (event.target.value === "automatic") {
            setPowerEstimationData((cond) => {
                if (roomheatingDemandForecast) {
                    cond.roomheatingDemand = roomheatingDemandForecast;
                    setYearLabel(roomheatingDemandForecastYear);
                } else if (roomheatingDemandConsumption) {
                    cond.roomheatingDemand = roomheatingDemandConsumption;
                    setYearLabel(roomheatingDemandConsumptionYear);
                }
            });
        } else if (event.target.value === "manual") {
            setPowerEstimationData((cond) => {
                cond.roomheatingDemand = {display: "0", real: 0};
            });
            setYearLabel(null);

        }
    };

    const fetchPowerEstimation = (powerEstimationData) => {
        setFetchingConsumptionEstimation(true);
        api_client.post(`/buildings/${currentBuilding.id}/heating-systems-states/power-estimation/`, powerEstimationData).then((response) => {
            const data = addDisplayNumbersInJson(response.data.data, []);
            if (response.data.status === "SUCCESS") {
                addToastMessage(
                    "Die überschlägige Heizlast wurde erfolgreich berechnet.",
                    "success",
                    true,
                    6000
                );
                setFetchingConsumptionEstimation(false);
                setHeatingSystemPower(data.power);
                setHeatingSystemPowerEstimationModalOpen(false);
            } else {
                    addToastMessage(
                        "Bei der Schätzung der Anlagenleistung ist ein Fehler aufgetreten, bitte versuchen Sie es später erneut.",
                        "error",
                        true,
                        6000
                    );
                    setFetchingConsumptionEstimation(false);
                    setHeatingSystemPowerEstimationModalOpen(false);
                }
            }
            ).catch((error) => {
                addToastMessage(
                    "Bei der Schätzung der Anlagenleistung ist ein Fehler aufgetreten, bitte versuchen Sie es später erneut.",
                    "error",
                    true,
                    6000
                );
                setFetchingConsumptionEstimation(false);
                setHeatingSystemPowerEstimationModalOpen(false);
            });
            
        };

    const fetchRoomheatingDemand = useCallback(async (type) => {
        // make sure estimationType is set
        if (demandEstimationType === null) {
            return;
        }
        setFetchingConsumptionEstimation(true);
        api_client.post(`/buildings/${currentBuilding.id}/roomheating-demand/`, { year: year, estimate: type === "forecast" })
        .then((response) => {
            const data = addDisplayNumbersInJson(response.data.data);
            if (type === "forecast") {
                if (data.roomheating_demand_per_sqm) {
                    setRoomheatingDemandForecast(data.roomheating_demand_per_sqm);
                    setRoomheatingDemandForecastYear(data.year);
                    setPowerEstimationData((cond) => {
                        cond.roomheatingDemand = data.roomheating_demand_per_sqm;
                    });
                    setYearLabel(data.year);
                }
            } else {
                if (data.roomheating_demand_per_sqm) {
                    setRoomheatingDemandConsumption(data.roomheating_demand_per_sqm);
                    setRoomheatingDemandConsumptionYear(data.year);
                }
            }
            setFetchingConsumptionEstimation(false);
        })
        .catch((error) => {
            addToastMessage(
            "Beim Abruf des Raumwärmebedarfs ist ein Fehler aufgetreten, bitte schätzen Sie den Raumwärmebedarf manuell ab.",
            "error",
            true,
            6000
            );
            setFetchingConsumptionEstimation(false);
        });
    }, [year, demandEstimationType]);

    useEffect(() => {
        fetchRoomheatingDemand("forecast");
        fetchRoomheatingDemand("consumption");
    }, []);

    return (
        <>
        <Modal
            open={heatingSystemPowerEstimationModalOpen}
            onClose={() => setHeatingSystemPowerEstimationModalOpen(false)}
            aria-labelledby="power-estimation-modal"
        >
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    minWidth: 835,
                    maxWidth: 835,
                    bgcolor: "background.paper",
                    border: "2px solid #000",
                    boxShadow: 24,
                    p: 3,
                }}
            >
                {fetchingConsumptionEstimation && LoadingSpinner}
                <Button
                    sx={{
                        position: "absolute",
                        top: "10px",
                        right: "5px",
                    }}
                    onClick={() => setHeatingSystemPowerEstimationModalOpen(false)}
                >
                    <CloseIcon />
                </Button>
                <Typography id="modal-power-estimation-title" variant="h4" component="h2" sx={{ mb: 1 }}>
                    Heizlast schätzen
                </Typography>
                <Grid container sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                {/* Raumwärmebedarf */}
                <Box sx={{ width: "35%", height: "10ch", mt: 1, display: "flex",
                        flexDirection: "row",
                        alignItems: "center",  }}>
                <FloatInput
                    label={"Raumwärmebedarf" + (yearLabel ? " [" + yearLabel + "]" : "")}
                    size="small"
                    value={powerEstimationData.roomheatingDemand}
                    disabled={demandEstimationType === "automatic"}
                    onChange={(value) => setPowerEstimationData((cond) => {
                        cond.roomheatingDemand = value;
                    }
                    )}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Typography sx={{ mr: 1 }}>kWh/m²a</Typography>
                            </InputAdornment>
                        ),
                    }}
                    error={heatingSystemPowerEstimationError.roomheatingDemand}
                    setError={(error) => setHeatingSystemPowerEstimationError((cond) => {
                        cond.roomheatingDemand = error;
                    })}
                    validate={(value) => { return true; }}
                />
                </Box>
                {/* Radiogroup für Angabentyp des Raumwärmebedarfs */}
                <Box sx={{ width: "24%", height: "10ch", mt: 1.5,ml: 2.5, display: "flex",
                        flexDirection: "row",
                        alignItems: "center"  }}>
                <FormControl>
                    <RadioGroup
                        row
                        aria-labelledby="consumption-radio-buttons-group-label"
                        name="demand-estimation-radio-buttons-group"
                        onChange={demandEstimationRadioGroupHandleChange}
                        sx={{ mt: -0.5,ml: 1 }}
                    >
                        <Box sx={{ width: "100%",ml: 1 }}>
                        <FormControlLabel
                            value="manual"
                            control={<Radio />}
                            label="manuelle Angabe"
                            checked={demandEstimationType === "manual"}
                        />
                        </Box>
                        <Box sx={{ width: "100%", mt: -1, ml: 1 }}>
                        <FormControlLabel value="automatic" control={<Radio />} label="automatisch" checked={demandEstimationType === "automatic"}/>
                        </Box>
                    </RadioGroup>
                </FormControl>
                </Box>
                {/* Buttons für Übernahme der Prognose oder des Verbrauchs */}
                
                <Box sx={{ width: "30%", height: "10ch", mt: 0,ml: 0, display: "flex",
                        flexDirection: "row",
                        alignItems: "center"
                        }}>
                    {(demandEstimationType === "automatic") && (
                    <Grid container>
                    <CustomTooltip disableHoverListener={roomheatingDemandForecast && powerEstimationData.roomheatingDemand !== roomheatingDemandForecast} sx={{zIndex: 99999999}} placement="left-end" title={powerEstimationData.roomheatingDemand === roomheatingDemandForecast ? "Die Raumwärmebedarfsangabe wurde übernommen." : "Es sind ist kein Gebäudezustand vorhanden, der für die Schätzung der Heizlast dieser Anlage verwendet werden kann."}>
                    <Box sx={{ width: "100%", mt: 0, ml: 1 }}>
                        <Fab
                            variant="extended"
                            size="small"
                            disabled={!roomheatingDemandForecast || powerEstimationData.roomheatingDemand === roomheatingDemandForecast}
                            onClick={() => {setPowerEstimationData((cond) => {
                                cond.roomheatingDemand = roomheatingDemandForecast;
                            });
                            setYearLabel(roomheatingDemandForecastYear);
                            }}
                            color="default"
                            sx={{ mt: 0.8, width: "100%", zIndex: 0, boxShadow: 1, borderRadius: 1 }}
                            data-testid="roomheating-demand-forecast-button"
                        >
                            {powerEstimationData.roomheatingDemand === roomheatingDemandForecast && (
                                
                                    <CheckCircleTwoToneIcon sx={{color: "#8fbe00", position: "absolute", left: -10 }} />
                                
                            )}
                            {!roomheatingDemandForecast && (
                                    <ReportProblemTwoToneIcon sx={{color: "#ff9e17", position: "absolute", left: -10}} />
                            )}
                            Aus Prognose übernehmen
                        </Fab>
                    </Box>
                    </CustomTooltip>
                    <CustomTooltip disableHoverListener={roomheatingDemandConsumption && powerEstimationData.roomheatingDemand !== roomheatingDemandConsumption} sx={{zIndex: 99999999}} placement="left-end" title={powerEstimationData.roomheatingDemand === roomheatingDemandConsumption ? "Die Raumwärmebedarfsangabe wurde übernommen." : "Es sind sind keine passenden Verbrauchsdaten vorhanden, die für die Schätzung der Heizlast dieser Anlage verwendet werden können."}>
                    <Box sx={{ width: "100%", mt: 0, ml: 1 }}>
                        <Fab
                            variant="extended"
                            size="small"
                            disabled={!roomheatingDemandConsumption || powerEstimationData.roomheatingDemand === roomheatingDemandConsumption}
                            onClick={() => {
                                setPowerEstimationData((cond) => {
                                    cond.roomheatingDemand = roomheatingDemandConsumption;
                                });
                                setYearLabel(roomheatingDemandConsumptionYear);
                            }}
                            color="default"
                            sx={{ mt: 0.8, width: "100%", zIndex: 0, boxShadow: 1, borderRadius: 1 }}
                            data-testid="roomheating-demand-consumption-button"
                        >
                            {powerEstimationData.roomheatingDemand === roomheatingDemandConsumption && (
                                    <CheckCircleTwoToneIcon sx={{color: "#8fbe00", position: "absolute", left: -10 }} />
                            )}
                            {!roomheatingDemandConsumption && (
                                    <ReportProblemTwoToneIcon sx={{color: "#ff9e17", position: "absolute", left: -10 }} />)}
                            Aus Verbrauch übernehmen
                        </Fab>
                    </Box>
                    </CustomTooltip>
                    </Grid>
                    )}
                </Box>
                <Divider sx={{ mt: 1, mb: 3, width: "70%" }} />
                {/* Lastverteilung Raumwärme */}
                <Box sx={{width:"100%", ml: "3.8%"}}>
                <FloatInput
                    label="Lastanteil Raumwärme"
                    size="small"
                    sx={{ mr: 0.5, width: "30ch" }}
                    value={powerEstimationData.shareOfConsumptionRoomheating}
                    onChange={(value) => setPowerEstimationData((cond) => {
                        cond.shareOfConsumptionRoomheating = value;
                    }
                    )}
                    error={heatingSystemPowerEstimationError.shareOfConsumptionRoomheating}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Typography sx={{ mr: 1 }}>%</Typography>
                            </InputAdornment>
                        ),
                    }}
                    validate={(value) => { return value >= 0 && value <= 100; }}
                    setError={(error) => setHeatingSystemPowerEstimationError((cond) => {
                        cond.shareOfConsumptionRoomheating = error;
                    })}
                />
                </Box>
                </Grid>
                
                {/* Buttons */}
                <Grid container sx={{display: "flex", alignItems: "flex-end", justifyContent: "flex-end"}}>
                <Fab
                    variant="extended"
                    size="medium"
                    onClick={(e) => setHeatingSystemPowerEstimationModalOpen(false)}
                    color="default"
                    sx={{ mt: 4, mr: 1, width: "25%", zIndex: 0, boxShadow: 1, borderRadius: 1}}
                    data-testid="power-estimation-cancel-button"
                >
                    Abbrechen
                </Fab>
                <CustomTooltip disableHoverListener={roomheatingDemandForecast} title="Um die Heizlast zu schätzen ist ein Gebäudezustand mit Angabe der Wohnfläche notwendig. Bitte fügen Sie zuerst einen Gebäudezustand hinzu.">
                <Box sx={{width:"27%"}}>
                <Fab
                    variant="extended"
                    size="medium"
                    onClick={(e) => {fetchPowerEstimation({heating_system_type: powerEstimationData.heatingSystemType, consumption_estimate: powerEstimationData.roomheatingDemand.real, year: powerEstimationData.year, share_of_consumption_roomheating: powerEstimationData.shareOfConsumptionRoomheating.real}); 
                    }}
                    color="secondary"
                    sx={{ mt: 4, ml: 1,  width: "100%", zIndex: 0, boxShadow: 1, borderRadius: 1 }}
                    data-testid="power-estimation-start-button"
                    disabled={heatingSystemPowerEstimationError.roomheatingDemand || heatingSystemPowerEstimationError.shareOfConsumptionRoomheating || heatingSystemPowerEstimationError.degreeOfEfficiency || !roomheatingDemandForecast}
                >
                    <InsightsTwoToneIcon sx={{mr:1}} /> Heizlast schätzen
                </Fab>
                </Box>
                </CustomTooltip>
                </Grid>
                </Box>
        </Modal>
    </>
    );

}