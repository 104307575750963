const germanFormatter = Intl.NumberFormat("de-DE", { maximumFractionDigits: 2 });

export function germanNumberFormat(value) {
    return value.toLocaleString("de-DE", { maximumFractionDigits: 0 });
}

export function germanNumberStringToInternationalNumberString(string) {
    return string.replace(".", "").replace(",", ".");
}

export function parseGermanNumberString(numberString) {
    return Number(numberString.replace(/\./g, "").replace(",", "."));
}

export function createGermanNumberString(number, thousandsSeparator = true, maximumFractionDigits = 3) {
    if (!thousandsSeparator) {
        return number.toLocaleString("de-DE", { useGrouping: false, maximumFractionDigits: maximumFractionDigits });
    }
    return germanFormatter.format(number);
}

/**
 * Recursively cleans a JSON object by replacing objects with a 'real' property with their 'real' value.
 *
 * This function traverses a JSON object and its nested objects or arrays. If it encounters an object
 * with a 'real' property, it replaces the entire object with the value of 'real'. This is typically
 * used to process JSON data where numbers are wrapped in objects containing additional metadata, and
 * only the raw numbers are needed.
 *
 * @param {Object} json - The JSON object to be cleaned.
 * @returns {Object} A new JSON object with 'real' values extracted.
 *
 * @example
 * const data = {
 *   id: 1,
 *   name: "Sample",
 *   value: { display: "10.00", real: 10 },
 *   details: {
 *     count: { display: "5", real: 5 },
 *     items: [{ display: "2", real: 2 }, { display: "3", real: 3 }]
 *   }
 * };
 * const cleanedData = cleanNumbersInJson(data);
 * // cleanedData will be:
 * // {
 * //   id: 1,
 * //   name: "Sample",
 * //   value: 10,
 * //   details: {
 * //     count: 5,
 * //     items: [2, 3]
 * //   }
 * // }
 */
export function cleanNumbersInJson(json) {
    if (!json) {
        return json;
    }
    var new_json = { ...json };
    for (const [key, value] of Object.entries(new_json)) {
        if (value === null || value === undefined) {
            continue;
        }
        if (value.hasOwnProperty("real")) {
            new_json[key] = value.real;
        } else if (Array.isArray(value)) {
            new_json[key] = cleanNumbersInJsonArray(value);
        } else if (typeof value === "object") {
            new_json[key] = cleanNumbersInJson(value);
        }
    }
    return new_json;
}

/**
 * Executes the cleanNumbersInJson function on each element of an array.
 */
export function cleanNumbersInJsonArray(array) {
    if (!array) {
        return array;
    }
    const new_array = array.map((elem) => {
        if (typeof elem === "string" || elem instanceof String) {
            return elem;
        }
        return cleanNumbersInJson(elem);
    });
    return new_array;
}

/**
 * Recursively adds display formatting to numbers in a JSON object.
 *
 * This function traverses a JSON object and its nested objects or arrays. When it encounters a number,
 * it wraps the number in an object with 'display' and 'real' properties. The 'display' property contains
 * the number formatted as a German number string, while 'real' retains the original number. It skips
 * formatting for keys named 'id' or 'year'.
 *
 * @param {Object} json - The JSON object to be processed.
 * @returns {Object} A new JSON object with numbers formatted for display.
 *
 * @example
 * const data = {
 *   id: 1,
 *   year: 2021,
 *   value: 10,
 *   details: {
 *     count: 5,
 *     items: [2, 3]
 *   }
 * };
 * const formattedData = addDisplayNumbersInJson(data);
 * // formattedData will be:
 * // {
 * //   id: 1,
 * //   year: 2021,
 * //   value: { display: "10,00", real: 10 },
 * //   details: {
 * //     count: { display: "5,00", real: 5 },
 * //     items: [{ display: "2,00", real: 2 }, { display: "3,00", real: 3 }]
 * //   }
 * // }
 */
export function addDisplayNumbersInJson(json, excludeKeys = ["id", "year"]) {
    if (!json) {
        return json;
    }
    var new_json = { ...json };
    for (const [key, value] of Object.entries(new_json)) {
        if (value == null || excludeKeys.includes(key)) {
            continue;
        } 
        if (typeof value === "number") {
            new_json[key] = { display: germanFormatter.format(value), real: value };
        // also check for arrays and recursively format
        } else if (Array.isArray(value)) {
            new_json[key] = addDisplayNumbersInJsonArray(value, excludeKeys);
        // also check for nested objects and recursively format
        } else if (typeof value === "object" && Object.keys(value).length > 0) {
            new_json[key] = addDisplayNumbersInJson(value, excludeKeys);
        }
    }
    return new_json;
}

/**
 * Executes the addDisplayNumbersInJson function on each element of an array.
 */
export function addDisplayNumbersInJsonArray(array, excludeKeys = ["id", "year"]) {
    if (!array) {
        return array;
    }
    const new_array = array.map((elem) => {
        return addDisplayNumbersInJson(elem, excludeKeys);
    });
    return new_array;
}
