/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useCallback, useContext, useEffect } from "react";
import { useImmer } from "use-immer";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Box,
    FormControl,
    Tabs,
    Tab,
    Fab,
    Modal,
    FormControlLabel,
    Paper,
    Stack,
    Divider,
    RadioGroup,
    Radio,
    InputAdornment,
    Alert,
    Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import api_client from "../../services/http-common";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import { useTheme } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import SolarPowerIcon from "@mui/icons-material/SolarPower";
import { FloatInput, BasicTextField, BasicSelectField, YearPicker } from "../../components/InputFields";
import { addDisplayNumbersInJson, addDisplayNumbersInJsonArray, cleanNumbersInJson } from "../../utils/formatting";
import { createGermanNumberString } from "../../utils/formatting";
import DeleteIcon from "@mui/icons-material/Delete";
import ToastContext from "../../contexts/ToastContext";
import UpdateTimeStampContext from "./UpdateTimestampContext";
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { useBuildingData } from "../../components/FetchData";

const NumberInput = styled(BasicTextField)`
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        display: none;
    }
`;

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "left",
    color: theme.palette.text.primary,
}));

/**
 * Component for displaying and managing photovoltaic (PV) data for a building in an accordion format.
 *
 * This component allows users to view, add, edit, and delete PV data for a specific building. It supports both
 * measured and estimated PV data, including yearly yield, roof area, and estimated power. The component provides
 * interactive elements for managing PV data, such as tabs for different years, input fields, and modals for
 * confirmation actions. It also integrates with backend services for data fetching and saving.
 *
 * Props:
 *   currentBuilding (Object): The building object for which the PV data are being managed.
 *
 * Returns:
 *   ReactElement: A component that renders an accordion with PV data details and management options.
 */
export const BuildingPvAccordion = ({ currentBuilding }) => {
    // -- CONTEXT -- //
    const { addToastMessage } = useContext(ToastContext);
    const { setUpdateTimestamp } = useContext(UpdateTimeStampContext);

    // -- CONSTANTS -- //
    const theme = useTheme();
    const emptyBuildingPvMeasurement = { amount: { display: "", real: 0 } };
    var emptyBuildingPvEstimation = {
        power_based: false,
        actual_power: { display: "", real: 0 },
        roof_area: { display: "", real: 0 },
        roof_type: "Satteldach",
        yearly_yield_per_kwp: { display: "", real: 0 },
        calculated_power: { display: "", real: 0 },
    };
    const emptyBuildingPv = {
        year: 2000,
        calculation_type: "measurement",
        measurement: emptyBuildingPvMeasurement,
        estimation: emptyBuildingPvEstimation,
    };

    // -- STATES -- //
    // building pv data
    const [buildingPvTab, setBuildingPvTab] = useState(0);
    const [buildingPv, setBuildingPv] = useImmer([emptyBuildingPv]);

    const [editModeBuildingPv, setEditModeBuildingPv] = useState(false);
    const [errorBuildingPv, setErrorBuildingPv] = useImmer({});

    const [fetchingBuildingPvData, setFetchingBuildingPvData] = useState(false);

    const [addPvYearModalOpen, setAddPvYearModalOpen] = useState(false);
    const [addPvYearChoice, setAddPvYearChoice] = useState(null);
    const [addPvYearValid, setAddPvYearValid] = useState(false);
    const handleOpenAddPvYear = () => setAddPvYearModalOpen(true);
    const handleCloseAddPvYear = () => setAddPvYearModalOpen(false);

    // pv config data
    const [pvConfig, setPvConfig] = useState(null);

    // handle functions for delete confirmation modal
    const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false);
    const handleOpenDeleteConfirmationModal = () => setDeleteConfirmationModalOpen(true);
    const handleCloseDeleteConfirmationModal = () => setDeleteConfirmationModalOpen(false);

    // add years (tabs) to building pv
    const addBuildingPvTab = () => {
        handleCloseAddPvYear();
        const newTabIdx = buildingPv.length;
        setBuildingPv((pv) => {
            var newEl = {
                year: parseInt(addPvYearChoice),
                calculation_type: "measurement",
                measurement: emptyBuildingPvMeasurement,
                estimation: { ...emptyBuildingPvEstimation },
            };
            newEl.estimation.yearly_yield_per_kwp = {
                display: createGermanNumberString(pvConfig.yearly_kwh_per_kwp),
                real: pvConfig.yearly_kwh_per_kwp,
            };
            pv.push(newEl);
        });
        setAddPvYearChoice("");
        setAddPvYearValid(false);
        setBuildingPvTab(newTabIdx);
        setEditModeBuildingPv(true);
    };

    // handle the switching of building-pv year (tab)
    const handleBuildingPvTab = (event, newValue) => {
        setBuildingPvTab(newValue);
    };

    // fetch building pv from backend
    const fetchBuildingPvData = useCallback(async () => {
        setEditModeBuildingPv(false);
        setFetchingBuildingPvData(true);
        api_client.get(`/buildings/${currentBuilding.id}/pv/`, {}).then((response) => {
            const data = addDisplayNumbersInJsonArray(response.data.pv);

            // assign local state
            setBuildingPv((pv) => {
                pv.splice(0, pv.length);
                data.map((val) => {
                    pv.push(val);
                    return pv;
                });
            });
            var pvTab = Math.max(0, Math.min(data.length - 1, buildingPvTab));
            setBuildingPvTab(pvTab);
            setPvConfig(response.data.pv_config);
            setFetchingBuildingPvData(false);
        });
    }, [currentBuilding, buildingPvTab]);

    useEffect(() => {
        if (currentBuilding.id !== -1) {
            fetchBuildingPvData();
        }
    }, [currentBuilding]);

    // handle the toggling of the edit modes
    const handleEditMode = () => {
        if (editModeBuildingPv === true) {
            fetchBuildingPvData();
            setEditModeBuildingPv(false);
        } else {
            setEditModeBuildingPv(true);
        }
        setErrorBuildingPv({});
    };

    // handle the saving after editing
    const handleSave = async () => {
        setEditModeBuildingPv((prevEditMode) => !prevEditMode);
        setFetchingBuildingPvData(true);
        var _buildingPv = cleanNumbersInJson(buildingPv[buildingPvTab]);
        // post building-pv to backend
        api_client.post(`/buildings/${currentBuilding.id}/pv/`, _buildingPv).then((response) => {
            const building_pv_status = response.status;
            if (building_pv_status === 200) {
                const data = addDisplayNumbersInJson(response.data.pv);
                setBuildingPv((pv) => {
                    pv[buildingPvTab] = data;
                });
            }
            setUpdateTimestamp(Date.now());
            setFetchingBuildingPvData(false);
        });
    };

    // delete building consumption
    const deleteBuildingPv = async () => {
        handleCloseDeleteConfirmationModal();
        setFetchingBuildingPvData(true);
        api_client
            .delete(`/buildings/${currentBuilding.id}/pv/${buildingPv[buildingPvTab].year}/`, {})
            .then((response) => {
                setBuildingPvTab(Math.max(0, Math.min(buildingPv.length - 2, buildingPvTab)));
                if (response.status === 200) {
                    fetchBuildingPvData();
                    setUpdateTimestamp(Date.now());
                    addToastMessage("PV-Jahr erfolgreich gelöscht.", "success", true, 6000, "toast-delete-pv-success");
                } else {
                    addToastMessage(
                        "Beim Löschen des PV-Jahres ist ein Fehler aufgetreten, bitte versuchen Sie es erneut.",
                        "error",
                        true,
                        6000,
                        "toast-delete-pv-error"
                    );
                }
            });
        setFetchingBuildingPvData(false);
    };

    // handle PV CalculationType RadioButton selection
    const buildingPvCalculationRadioGroupHandleChange = (event) => {
        if (event.target.value === "measurement" && !buildingPv[buildingPvTab].hasOwnProperty("measurement")) {
            setBuildingPv((pv) => {
                pv[buildingPvTab].measurement = emptyBuildingPvMeasurement;
                pv[buildingPvTab].calculation_type = event.target.value;
            });
        } else if (event.target.value === "estimation" && !buildingPv[buildingPvTab].hasOwnProperty("estimation")) {
            setBuildingPv((pv) => {
                pv[buildingPvTab].estimation = emptyBuildingPvEstimation;
                pv[buildingPvTab].calculation_type = event.target.value;
            });
        } else {
            setBuildingPv((pv) => {
                pv[buildingPvTab].calculation_type = event.target.value;
            });
        }
    };

    // handle PV EstimationType RadioButton selection
    const buildingPvEstimationRadioGroupHandleChange = (event) => {
        setBuildingPv((pv) => {
            pv[buildingPvTab].estimation.power_based = event.target.value === "power";
        });
    };

    const { data: buildingData, isLoading, isError: isErrorBuildingData } = useBuildingData(currentBuilding);
    // Set a default value for the building year as this value is not that important for rendering and can be
    // set again when the data is fetched. Otherwise buildingData.construction_year might be undefined at first
    const minYear = buildingData?.construction_year || 1800;

    function calculateEstimatedPvPower() {
        setBuildingPv((pv) => {
            if (
                pvConfig &&
                buildingPv[buildingPvTab] &&
                buildingPv[buildingPvTab].hasOwnProperty("calculation_type") &&
                buildingPv[buildingPvTab].calculation_type === "estimation"
            ) {
                const factor =
                    pv[buildingPvTab].estimation.roof_type === "Satteldach"
                        ? pvConfig.sqm_per_kwp_gable_roof
                        : pvConfig.sqm_per_kwp_flat_roof;
                if (factor > 0 && pv[buildingPvTab].estimation.roof_area.real > 0) {
                    const val = Math.round((pv[buildingPvTab].estimation.roof_area.real / factor) * 10) / 10;
                    pv[buildingPvTab].estimation.calculated_power.real = val;
                    pv[buildingPvTab].estimation.calculated_power.display = createGermanNumberString(val);
                } else {
                    pv[buildingPvTab].estimation.calculated_power.real = 0;
                    pv[buildingPvTab].estimation.calculated_power.display = "0";
                }
            }
        });
    }

    return (
        <>
            <DeleteConfirmationModal
                deleteConfirmationModalOpen={deleteConfirmationModalOpen}
                handleCloseDeleteConfirmationModal={handleCloseDeleteConfirmationModal}
                confirmationText={
                    "Sind Sie sicher, dass Sie " +
                    'die PV-Angaben zum Jahr "' +
                    buildingPv[buildingPvTab]?.year +
                    '"' +
                    " und alle zugehörigen Daten löschen möchten?"
                }
                deletionTargetFunction={deleteBuildingPv}
            />
            <Box sx={{ mt: 3, pb: 30 }}>
                <Accordion sx={{ width: "160ch" }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <SolarPowerIcon sx={{ fontSize: 30 }} />{" "}
                        <Typography
                            sx={{
                                ml: 2,
                                fontSize: 20,
                                fontWeight: "bold",
                            }}
                        >
                            Photovoltaik
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                        }}
                    >
                        <Divider sx={{ mb: 2, mt: -2, width: "100%" }} />
                        {fetchingBuildingPvData && LoadingSpinner}
                        <Box sx={{ ml: 0 }}>
                            <Fab
                                variant="extended"
                                size="medium"
                                onClick={handleOpenAddPvYear}
                                disabled={editModeBuildingPv}
                                sx={{ zIndex: 0, boxShadow: 1, borderRadius: 1 }}
                            >
                                <AddIcon sx={{ mr: 1 }} />
                                Jahr hinzufügen
                            </Fab>
                            <Modal
                                open={addPvYearModalOpen}
                                onClose={handleCloseAddPvYear}
                                aria-labelledby="add-year-modal"
                            >
                                <Box
                                    sx={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                        width: 500,
                                        bgcolor: "background.paper",
                                        border: "2px solid #000",
                                        boxShadow: 24,
                                        p: 4,
                                    }}
                                >
                                    <Button
                                        sx={{
                                            position: "absolute",
                                            top: "10px",
                                            right: "5px",
                                        }}
                                        onClick={() => handleCloseAddPvYear()}
                                    >
                                        <CloseIcon />
                                    </Button>
                                    <Typography id="modal-modal-title" variant="h6" component="h2">
                                        Für welches Jahr möchten Sie Photovoltaik Daten hinzufügen?
                                    </Typography>
                                    <YearPicker
                                        value={addPvYearChoice}
                                        variant="outlined"
                                        error={!addPvYearValid}
                                        minYear={minYear}
                                        onChange={(e) => {
                                            if (
                                                parseInt(e.target.value) < minYear ||
                                                parseInt(e.target.value) > 2050 ||
                                                !buildingPv.every((c) => c.year !== parseInt(e.target.value))
                                            ) {
                                                setAddPvYearValid(false);
                                            } else {
                                                setAddPvYearValid(true);
                                            }
                                            setAddPvYearChoice(parseInt(e.target.value));
                                        }}
                                        sx={{ mt: 1 }}
                                        size="small"
                                        label="Jahr"
                                        type="number"
                                    />
                                    <Fab
                                        disabled={!addPvYearValid}
                                        onClick={(e) => addBuildingPvTab()}
                                        variant="extended"
                                        size="medium"
                                        color="secondary"
                                        sx={{ ml: 3, mt: 0.8, boxShadow: 1, borderRadius: 1 }}
                                    >
                                        Speichern
                                    </Fab>
                                </Box>
                            </Modal>
                            {buildingPv.length > 0 ? (
                                <>
                                    <Box component="form" noValidate autoComplete="off">
                                        <div
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "0.5rem",
                                                mt: 2,
                                                border: 1,
                                                borderRadius: 2,
                                            }}
                                        >
                                            <div
                                                sx={{
                                                    marginLeft: "auto",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                }}
                                            >
                                                <Box
                                                    sx={{ bgcolor: theme.palette.secondary.main, borderRadius: "5px" }}
                                                    width="100%"
                                                >
                                                    <Tabs
                                                        TabIndicatorProps={{
                                                            children: <span className="MuiTabs-indicatorSpan" />,
                                                        }}
                                                        value={buildingPvTab}
                                                        onChange={handleBuildingPvTab}
                                                        variant="scrollable"
                                                        scrollButtons={true}
                                                        sx={{
                                                            borderColor: theme.palette.neutral.light,
                                                            height: "20px",
                                                            // bgcolor: theme.palette.primary.main,
                                                            mb: 1,
                                                            mt: 2,
                                                            "& .MuiTabs-scrollButtons.Mui-disabled": {
                                                                opacity: 0.3,
                                                            },
                                                            "& .Mui-selected": {
                                                                color: theme.palette.background.default,
                                                            },
                                                            "& .MuiTabs-indicator": {
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                backgroundColor: "transparent",
                                                                height: 4,
                                                                mb: "7px",
                                                            },
                                                            "& .MuiTabs-indicatorSpan": {
                                                                maxWidth: 50,
                                                                width: "100%",
                                                                backgroundColor: theme.palette.background.default,
                                                                height: "100%",
                                                            },
                                                        }}
                                                    >
                                                        {buildingPv.map((val, index) => {
                                                            return (
                                                                <Tab
                                                                    disabled={editModeBuildingPv}
                                                                    label={val.year}
                                                                    id={index}
                                                                    sx={{ fontWeight: "bold", fontSize: "20px" }}
                                                                    key={"building-pv-tab-" + index}
                                                                />
                                                            );
                                                        })}
                                                    </Tabs>
                                                </Box>

                                                <Fab
                                                    variant="extended"
                                                    size="medium"
                                                    color={editModeBuildingPv ? "default" : "secondary"}
                                                    onClick={() => {
                                                        handleEditMode();
                                                    }}
                                                    sx={{
                                                        marginRight: "1rem",
                                                        zIndex: 0,
                                                        boxShadow: 1,
                                                        borderRadius: 1,
                                                    }}
                                                >
                                                    {editModeBuildingPv ? <></> : <EditIcon sx={{ mr: 1 }} />}
                                                    {editModeBuildingPv ? "Abbrechen" : "Bearbeiten"}
                                                </Fab>
                                                {editModeBuildingPv && (
                                                    <Fab
                                                        disabled={
                                                            Object.keys(errorBuildingPv).length > 0 &&
                                                            !Object.values(errorBuildingPv).every(
                                                                (item) => item === false
                                                            )
                                                        }
                                                        variant="extended"
                                                        size="medium"
                                                        color={editModeBuildingPv ? "secondary" : "default"}
                                                        onClick={() => handleSave()}
                                                        sx={{
                                                            marginRight: "1rem",
                                                            zIndex: 0,
                                                            boxShadow: 1,
                                                            borderRadius: 1,
                                                        }}
                                                    >
                                                        <SaveIcon sx={{ mr: 1 }} />
                                                        Speichern
                                                    </Fab>
                                                )}
                                                <Fab
                                                    variant="extended"
                                                    size="small"
                                                    disabled={editModeBuildingPv}
                                                    onClick={(e) => {
                                                        handleOpenDeleteConfirmationModal();
                                                    }}
                                                    sx={{ zIndex: 0, boxShadow: 1, borderRadius: 1 }}
                                                >
                                                    <DeleteIcon />
                                                    PV-Jahr löschen
                                                </Fab>
                                            </div>
                                            <div>
                                                <FormControl>
                                                    <RadioGroup
                                                        row
                                                        aria-labelledby="building-pv-radio-buttons-group-label"
                                                        name="building-pv-row-radio-buttons-group"
                                                        onChange={buildingPvCalculationRadioGroupHandleChange}
                                                        sx={{ mt: 2 }}
                                                        data-testid="building-selection-radio-group"
                                                    >
                                                        <Typography mt={1.1} mr={3}>
                                                            Berechnung basierend auf
                                                        </Typography>
                                                        <FormControlLabel
                                                            value="measurement"
                                                            control={<Radio />}
                                                            label="gemessenen"
                                                            checked={
                                                                buildingPv[buildingPvTab] &&
                                                                buildingPv[buildingPvTab].hasOwnProperty(
                                                                    "calculation_type"
                                                                )
                                                                    ? buildingPv[buildingPvTab].calculation_type ===
                                                                      "measurement"
                                                                    : true
                                                            }
                                                            data-testid="building-pv-radio-measurement"
                                                            sx={{
                                                                "& .MuiFormControlLabel-label": {
                                                                    fontWeight: "bold",
                                                                },
                                                            }}
                                                            disabled={!editModeBuildingPv}
                                                        />
                                                        <Divider orientation="vertical" flexItem />
                                                        <FormControlLabel
                                                            sx={{
                                                                ml: 1,
                                                                "& .MuiFormControlLabel-label": {
                                                                    fontWeight: "bold",
                                                                },
                                                            }}
                                                            value="estimation"
                                                            control={<Radio />}
                                                            checked={
                                                                buildingPv[buildingPvTab] &&
                                                                buildingPv[buildingPvTab].hasOwnProperty(
                                                                    "calculation_type"
                                                                )
                                                                    ? buildingPv[buildingPvTab].calculation_type ===
                                                                      "estimation"
                                                                    : false
                                                            }
                                                            label="geschätzten"
                                                            data-testid="building-pv-radio-estimation"
                                                            disabled={!editModeBuildingPv}
                                                        />
                                                        <Typography mt={1.1} ml={1}>
                                                            Werten
                                                        </Typography>
                                                    </RadioGroup>
                                                </FormControl>
                                                <Divider sx={{ mt: 2, width: 600 }}></Divider>
                                                <Stack sx={{ mt: 2 }}>
                                                    <Item>
                                                        <Box sx={{ m: 1 }}>
                                                            {(
                                                                buildingPv[buildingPvTab] &&
                                                                buildingPv[buildingPvTab].hasOwnProperty(
                                                                    "calculation_type"
                                                                )
                                                                    ? buildingPv[buildingPvTab].calculation_type ===
                                                                      "measurement"
                                                                    : true
                                                            ) ? (
                                                                <div>
                                                                    <Typography variant="h4">
                                                                        Gemessene Werte
                                                                    </Typography>
                                                                    <FloatInput
                                                                        variant={
                                                                            editModeBuildingPv ? "outlined" : "filled"
                                                                        }
                                                                        label="Jahresertrag"
                                                                        size="small"
                                                                        value={
                                                                            buildingPv[buildingPvTab] &&
                                                                            buildingPv[buildingPvTab].hasOwnProperty(
                                                                                "measurement"
                                                                            )
                                                                                ? buildingPv[buildingPvTab].measurement
                                                                                      .amount
                                                                                : 0
                                                                        }
                                                                        onChange={(val) =>
                                                                            setBuildingPv((pv) => {
                                                                                pv[[buildingPvTab]].measurement.amount =
                                                                                    val;
                                                                            })
                                                                        }
                                                                        disabled={!editModeBuildingPv}
                                                                        sx={{ mt: 2, width: "25ch" }}
                                                                        InputProps={{
                                                                            endAdornment: (
                                                                                <InputAdornment
                                                                                    position="end"
                                                                                    sx={{
                                                                                        mt: editModeBuildingPv
                                                                                            ? 0.5
                                                                                            : 0.5,
                                                                                    }}
                                                                                >
                                                                                    {"kWh"}
                                                                                </InputAdornment>
                                                                            ),
                                                                        }}
                                                                        validate={(val) => {
                                                                            return true;
                                                                        }}
                                                                        error={errorBuildingPv.measurement_amount}
                                                                        setError={(val) => {
                                                                            setErrorBuildingPv((err) => {
                                                                                err.measurement_amount = val;
                                                                            });
                                                                        }}
                                                                    />
                                                                </div>
                                                            ) : (
                                                                <div>
                                                                    <Typography variant="h4">Schätzung</Typography>
                                                                    <FormControl>
                                                                        <RadioGroup
                                                                            row
                                                                            aria-labelledby="building-pv-radio-buttons-group-label"
                                                                            name="building-pv-row-radio-buttons-group"
                                                                            onChange={
                                                                                buildingPvEstimationRadioGroupHandleChange
                                                                            }
                                                                            sx={{ mt: 2 }}
                                                                            data-testid="building-selection-radio-group"
                                                                        >
                                                                            <Typography mt={1.1} mr={3}>
                                                                                Schätzung basierend auf
                                                                            </Typography>
                                                                            <FormControlLabel
                                                                                value="power"
                                                                                control={<Radio />}
                                                                                label="Anlagenleistung"
                                                                                checked={
                                                                                    buildingPv[buildingPvTab].estimation
                                                                                        .power_based
                                                                                }
                                                                                data-testid="building-pv-estimation-radio-power"
                                                                                sx={{
                                                                                    "& .MuiFormControlLabel-label": {
                                                                                        fontWeight: "bold",
                                                                                    },
                                                                                }}
                                                                                disabled={!editModeBuildingPv}
                                                                            />
                                                                            <Divider orientation="vertical" flexItem />
                                                                            <FormControlLabel
                                                                                sx={{
                                                                                    ml: 1,
                                                                                    "& .MuiFormControlLabel-label": {
                                                                                        fontWeight: "bold",
                                                                                    },
                                                                                }}
                                                                                value={"roofarea"}
                                                                                control={<Radio />}
                                                                                checked={
                                                                                    !buildingPv[buildingPvTab]
                                                                                        .estimation.power_based
                                                                                }
                                                                                label="Dachfläche"
                                                                                data-testid="building-pv-estimation-radio-roofarea"
                                                                                disabled={!editModeBuildingPv}
                                                                            />
                                                                        </RadioGroup>
                                                                    </FormControl>

                                                                    {buildingPv[buildingPvTab].estimation
                                                                        .power_based ? (
                                                                        <div>
                                                                            <FloatInput
                                                                                variant={
                                                                                    editModeBuildingPv
                                                                                        ? "outlined"
                                                                                        : "filled"
                                                                                }
                                                                                label="Anlagenleistung"
                                                                                size="small"
                                                                                value={
                                                                                    buildingPv[buildingPvTab].estimation
                                                                                        .actual_power
                                                                                }
                                                                                onChange={(val) =>
                                                                                    setBuildingPv((pv) => {
                                                                                        pv[
                                                                                            [buildingPvTab]
                                                                                        ].estimation.actual_power = val;
                                                                                    })
                                                                                }
                                                                                disabled={!editModeBuildingPv}
                                                                                sx={{
                                                                                    mt: 2,
                                                                                    mb: 2,
                                                                                    mr: 2,
                                                                                    width: "30ch",
                                                                                }}
                                                                                InputProps={{
                                                                                    endAdornment: (
                                                                                        <InputAdornment
                                                                                            position="end"
                                                                                            sx={{
                                                                                                mt: editModeBuildingPv
                                                                                                    ? 0
                                                                                                    : 2,
                                                                                            }}
                                                                                        >
                                                                                            {"kWp"}
                                                                                        </InputAdornment>
                                                                                    ),
                                                                                }}
                                                                                validate={(val) => {
                                                                                    return true;
                                                                                }}
                                                                                error={
                                                                                    errorBuildingPv.estimation_actual_power
                                                                                }
                                                                                setError={(val) => {
                                                                                    setErrorBuildingPv((err) => {
                                                                                        err.estimation_actual_power =
                                                                                            val;
                                                                                    });
                                                                                }}
                                                                            />
                                                                            <div>
                                                                                <FloatInput
                                                                                    variant={
                                                                                        editModeBuildingPv
                                                                                            ? "outlined"
                                                                                            : "filled"
                                                                                    }
                                                                                    label="Jährlicher Ertrag"
                                                                                    size="small"
                                                                                    value={
                                                                                        buildingPv[buildingPvTab]
                                                                                            .estimation
                                                                                            .yearly_yield_per_kwp
                                                                                    }
                                                                                    onChange={(val) =>
                                                                                        setBuildingPv((pv) => {
                                                                                            pv[
                                                                                                [buildingPvTab]
                                                                                            ].estimation.yearly_yield_per_kwp =
                                                                                                val
                                                                                                    ? val
                                                                                                    : {
                                                                                                          display: "0",
                                                                                                          real: 0,
                                                                                                      };
                                                                                        })
                                                                                    }
                                                                                    disabled={!editModeBuildingPv}
                                                                                    sx={{
                                                                                        m: 2,
                                                                                        ml: 0,
                                                                                        mt: 1,
                                                                                        width: "30ch",
                                                                                    }}
                                                                                    InputProps={{
                                                                                        endAdornment: (
                                                                                            <InputAdornment
                                                                                                position="end"
                                                                                                sx={{
                                                                                                    mt: editModeBuildingPv
                                                                                                        ? 0
                                                                                                        : 2,
                                                                                                }}
                                                                                            >
                                                                                                {"kWh/kWp"}
                                                                                            </InputAdornment>
                                                                                        ),
                                                                                    }}
                                                                                    validate={(val) => {
                                                                                        return true;
                                                                                    }}
                                                                                    error={
                                                                                        errorBuildingPv.estimation_yearly_yield
                                                                                    }
                                                                                    setError={(val) => {
                                                                                        setErrorBuildingPv((err) => {
                                                                                            err.estimation_yearly_yield =
                                                                                                val;
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    ) : (
                                                                        <div>
                                                                            <BasicSelectField
                                                                                FormControlProps={{
                                                                                    sx: {
                                                                                        m: 2,
                                                                                        ml: 0,
                                                                                    },
                                                                                }}
                                                                                InputLabelProps={{
                                                                                    disabled: !editModeBuildingPv,
                                                                                    sx: {
                                                                                        mt:
                                                                                            !editModeBuildingPv &&
                                                                                            buildingPv[buildingPvTab]
                                                                                                .estimation.roof_type
                                                                                                ? 1.5
                                                                                                : buildingPv[
                                                                                                      buildingPvTab
                                                                                                  ].estimation.roof_type
                                                                                                ? 0
                                                                                                : -0.75,
                                                                                    },
                                                                                }}
                                                                                variant={
                                                                                    editModeBuildingPv
                                                                                        ? "outlined"
                                                                                        : "filled"
                                                                                }
                                                                                label="Dachtyp"
                                                                                size="small"
                                                                                value={
                                                                                    buildingPv[buildingPvTab].estimation
                                                                                        .roof_type
                                                                                }
                                                                                onChange={(e) => {
                                                                                    setBuildingPv((pv) => {
                                                                                        pv[
                                                                                            buildingPvTab
                                                                                        ].estimation.roof_type =
                                                                                            e.target.value;
                                                                                    });
                                                                                    calculateEstimatedPvPower();
                                                                                }}
                                                                                disabled={!editModeBuildingPv}
                                                                                sx={{ width: "15ch" }}
                                                                                menuItems={[
                                                                                    {
                                                                                        name: "Satteldach",
                                                                                        value: "Satteldach",
                                                                                    },
                                                                                    {
                                                                                        name: "Flachdach",
                                                                                        value: "Flachdach",
                                                                                    },
                                                                                ]}
                                                                            />
                                                                            <FloatInput
                                                                                variant={
                                                                                    editModeBuildingPv
                                                                                        ? "outlined"
                                                                                        : "filled"
                                                                                }
                                                                                label={"Dachfläche"}
                                                                                size="small"
                                                                                value={
                                                                                    buildingPv[buildingPvTab].estimation
                                                                                        .roof_area
                                                                                }
                                                                                onChange={(val) =>
                                                                                    setBuildingPv((pv) => {
                                                                                        pv[
                                                                                            [buildingPvTab]
                                                                                        ].estimation.roof_area = val
                                                                                            ? val
                                                                                            : { display: "0", real: 0 };
                                                                                    })
                                                                                }
                                                                                disabled={!editModeBuildingPv}
                                                                                sx={{
                                                                                    m: 2,
                                                                                    ml: 0,
                                                                                    width: "20ch",
                                                                                }}
                                                                                InputProps={{
                                                                                    endAdornment: (
                                                                                        <InputAdornment
                                                                                            position="end"
                                                                                            sx={{
                                                                                                mt: editModeBuildingPv
                                                                                                    ? 0
                                                                                                    : 2,
                                                                                            }}
                                                                                        >
                                                                                            {"m\u00B2"}
                                                                                        </InputAdornment>
                                                                                    ),
                                                                                }}
                                                                                validate={(val) => {
                                                                                    return true;
                                                                                }}
                                                                                error={
                                                                                    errorBuildingPv.estimation_roof_area
                                                                                }
                                                                                setError={(val) => {
                                                                                    setErrorBuildingPv((err) => {
                                                                                        err.estimation_roof_area = val;
                                                                                    });
                                                                                }}
                                                                                onBlur={() =>
                                                                                    calculateEstimatedPvPower()
                                                                                }
                                                                            />

                                                                            <Typography
                                                                                sx={{
                                                                                    mt: 1,
                                                                                    ml: 1,
                                                                                }}
                                                                                variant="h6"
                                                                            >
                                                                                Geschätzte Anlagenleistung:
                                                                            </Typography>
                                                                            <FloatInput
                                                                                variant="standard"
                                                                                value={
                                                                                    buildingPv[buildingPvTab].estimation
                                                                                        .calculated_power
                                                                                }
                                                                                sx={{
                                                                                    mb: 3,
                                                                                    mt: 1,
                                                                                    ml: 1,
                                                                                    width: "12ch",
                                                                                }}
                                                                                InputProps={{
                                                                                    endAdornment: (
                                                                                        <InputAdornment position="end">
                                                                                            {"kWp"}
                                                                                        </InputAdornment>
                                                                                    ),
                                                                                    readOnly: true,
                                                                                }}
                                                                                validate={(val) => {
                                                                                    return true;
                                                                                }}
                                                                                error={false}
                                                                                setError={() => {}}
                                                                                onChange={() => {}}
                                                                            />
                                                                            <div>
                                                                                <FloatInput
                                                                                    variant={
                                                                                        editModeBuildingPv
                                                                                            ? "outlined"
                                                                                            : "filled"
                                                                                    }
                                                                                    label="Jährlicher Ertrag"
                                                                                    size="small"
                                                                                    value={
                                                                                        buildingPv[buildingPvTab]
                                                                                            .estimation
                                                                                            .yearly_yield_per_kwp
                                                                                    }
                                                                                    onChange={(val) =>
                                                                                        setBuildingPv((pv) => {
                                                                                            pv[
                                                                                                [buildingPvTab]
                                                                                            ].estimation.yearly_yield_per_kwp =
                                                                                                val
                                                                                                    ? val
                                                                                                    : {
                                                                                                          display: "0",
                                                                                                          real: 0,
                                                                                                      };
                                                                                        })
                                                                                    }
                                                                                    disabled={!editModeBuildingPv}
                                                                                    sx={{
                                                                                        m: 2,
                                                                                        ml: 0,
                                                                                        mt: 1,
                                                                                        width: "30ch",
                                                                                    }}
                                                                                    InputProps={{
                                                                                        endAdornment: (
                                                                                            <InputAdornment
                                                                                                position="end"
                                                                                                sx={{
                                                                                                    mt: editModeBuildingPv
                                                                                                        ? 0
                                                                                                        : 2,
                                                                                                }}
                                                                                            >
                                                                                                {"kWh/kWp"}
                                                                                            </InputAdornment>
                                                                                        ),
                                                                                    }}
                                                                                    validate={(val) => {
                                                                                        return true;
                                                                                    }}
                                                                                    error={
                                                                                        errorBuildingPv.estimation_yearly_yield
                                                                                    }
                                                                                    setError={(val) => {
                                                                                        setErrorBuildingPv((err) => {
                                                                                            err.estimation_yearly_yield =
                                                                                                val;
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </Box>
                                                    </Item>
                                                </Stack>
                                            </div>
                                        </div>
                                    </Box>
                                </>
                            ) : (
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        minHeight: "200px",
                                        width: "100%",
                                    }}
                                >
                                    <Alert severity="info" sx={{ textAlign: "left", height: "8ch", fontSize: 14 }}>
                                        <strong>Es sind keine Photovoltaik-Daten verfügbar.</strong>
                                        <br />
                                        Um Daten zu hinterlegen, fügen Sie ein Jahr über "Jahr hinzufügen" hinzu.
                                        <br />
                                    </Alert>
                                </Box>
                            )}
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </Box>
        </>
    );
};
