import React, { useState, useEffect } from "react";
import { TextField, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { createGermanNumberString, parseGermanNumberString } from "../utils/formatting";
import { useDebounce } from "../utils/debounce";

// For YearPicker
import dayjs, { Dayjs } from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

/**
 * Renders an integer input field with validation and debounce functionality.
 *
 * This component is a wrapper around the Material-UI TextField component, designed to handle integer inputs.
 * It includes validation to ensure the input is an integer and uses debouncing to limit the rate at which
 * input validation is performed. This is particularly useful for reducing the number of validations triggered
 * by continuous typing.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.value - The value object for the input, containing 'display' and 'real' properties.
 * @param {Function} props.onChange - The function to call when the input value changes.
 * @param {Function} props.validate - The function to validate the input value. Should return a boolean.
 * @param {boolean} props.error - The error state of the input.
 * @param {Function} props.setError - The function to set the error state of the input.
 * @param {string} [props.thousandsSeparator] - Optional thousands separator for formatting the display value.
 * @returns {JSX.Element} A TextField component tailored for integer input.
 *
 * @example
 * <IntegerInput
 *   value={{ display: '123', real: 123 }}
 *   onChange={handleChange}
 *   validate={(value) => value >= 0}
 *   error={errorState}
 *   setError={setErrorState}
 * />
 */

export function IntegerInput(props) {
    const [helperText, setHelperText] = useState("");

    const _validate = () => {
        if (!Number.isInteger(props.value.real)) {
            props.setError(true);
            setHelperText("Eingabe muss eine Zahl sein.");
        } else {
            props.setError(!props.validate(props.value.real));
            setHelperText("");
        }
    };

    const debouncedValidate = useDebounce(() => {
        _validate();
    });

    const handleBlur = (event) => {
        if (_validate()) {
            var parsedValue = Number(event.target.value);
            props.onChange({
                display: createGermanNumberString(parsedValue, props.thousandsSeparator),
                real: parsedValue,
                target: event.target,
            });
        }
    };

    const handleChange = (event) => {
        var parsedValue = Number(event.target.value);
        props.onChange({ display: event.target.value, real: parsedValue, target: event.target });
        debouncedValidate();
    };

    const { setError, thousandsSeparator, validate, ...textFieldProps } = props;

    return (
        <TextField
            {...textFieldProps}
            error={props.error}
            helperText={props.error ? helperText : ""}
            value={props.value?.display}
            onChange={handleChange}
            onBlur={handleBlur}
            type="number"
            sx={{ ...props.sx, backgroundColor: props.disabled ? "#f3f3f3" : "inherit" }}
        />
    );
}

/**
 * Renders an integer input field with validation, debounce functionality, and bounded value constraints.
 *
 * This component extends the basic integer input functionality by adding upper and lower bounds to the value.
 * It ensures that the input value stays within the specified range. The component is a wrapper around the
 * Material-UI TextField component and includes validation to ensure the input is an integer. It uses debouncing
 * to limit the rate at which input validation is performed, which is useful for reducing validations triggered
 * by continuous typing.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.value - The value object for the input, containing 'display' and 'real' properties.
 * @param {Function} props.onChange - The function to call when the input value changes.
 * @param {Function} props.validate - The function to validate the input value. Should return a boolean.
 * @param {boolean} props.error - The error state of the input.
 * @param {Function} props.setError - The function to set the error state of the input.
 * @param {number} props.minbound - The minimum bound for the input value.
 * @param {number} props.maxbound - The maximum bound for the input value.
 * @param {string} [props.thousandsSeparator] - Optional thousands separator for formatting the display value.
 * @returns {JSX.Element} A TextField component tailored for bounded integer input.
 *
 * @example
 * <BoundedIntegerInput
 *   value={{ display: '123', real: 123 }}
 *   onChange={handleChange}
 *   validate={(value) => value >= 0}
 *   error={errorState}
 *   setError={setErrorState}
 *   minbound={0}
 *   maxbound={100}
 * />
 */
export function BoundedIntegerInput(props) {
    const [helperText, setHelperText] = useState("");

    const _validate = () => {
        if (!Number.isInteger(props.value.real)) {
            props.setError(true);
            setHelperText("Eingabe muss eine Zahl sein.");
        } else {
            props.setError(!props.validate(props.value.real));
            setHelperText("");
        }
    };

    const debouncedValidate = useDebounce(() => {
        _validate();
    });

    const handleBlur = (event) => {
        if (_validate()) {
            var parsedValue = Number(event.target.value);
            var boundedValue = Math.max(Math.min(props.maxbound, parsedValue), props.minbound);
            props.onChange({
                display: createGermanNumberString(boundedValue, props.thousandsSeparator),
                real: boundedValue,
            });
        }
    };

    const handleChange = (event) => {
        var parsedValue = Number(event.target.value);
        props.onChange({ display: event.target.value, real: parsedValue });
        debouncedValidate();
    };

    const { setError, minbound, maxbound, thousandsSeparator, validate, ...textFieldProps } = props;

    return (
        <TextField
            {...textFieldProps}
            error={props.error}
            helperText={props.error ? helperText : ""}
            value={props.value?.display}
            onChange={handleChange}
            onBlur={handleBlur}
            type="number"
            sx={{ ...props.sx, backgroundColor: props.disabled ? "#f3f3f3" : "inherit" }}
        />
    );
}

/**
 * Renders a floating-point input field with validation, debounce functionality, and custom formatting.
 *
 * This component is designed for floating-point number inputs and includes validation to ensure the input
 * is a valid number. It uses debouncing to limit the rate at which input validation is performed, which is
 * useful for reducing validations triggered by continuous typing. The component also supports custom number
 * formatting, specifically German number formatting in this case. It is a wrapper around the Material-UI
 * TextField component.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.value - The value object for the input, containing 'display' and 'real' properties.
 * @param {Function} props.onChange - The function to call when the input value changes.
 * @param {Function} props.validate - The function to validate the input value. Should return a boolean.
 * @param {boolean} props.error - The error state of the input.
 * @param {Function} props.setError - The function to set the error state of the input.
 * @param {Function} [props.onBlur] - Optional function to call when the input loses focus.
 * @returns {JSX.Element} A TextField component tailored for floating-point number input.
 *
 * @example
 * <FloatInput
 *   value={{ display: '123,45', real: 123.45 }}
 *   onChange={handleChange}
 *   validate={(value) => value >= 0}
 *   error={errorState}
 *   setError={setErrorState}
 * />
 */
export function FloatInput(props = { thousandsSeparator: true, decimalPlaces: 3 }) {
    const [helperText, setHelperText] = useState("");

    const _validate = () => {
        if (isNaN(props.value.real)) {
            props.setError(true);
            setHelperText("Eingabe muss eine Zahl sein.");
            return false;
        } else {
            setHelperText("");
            var validation_success = props.validate(props.value.real);
            props.setError(!validation_success);
            return validation_success;
        }
    };

    const debouncedValidate = useDebounce(() => {
        _validate();
    });

    const handleBlur = (event) => {
        if (_validate()) {
            props.onChange({
                ...props.value,
                display: createGermanNumberString(props.value.real, props.thousandsSeparator, props.decimalPlaces),
            });
        }
        if (props.onBlur) {
            props.onBlur(event);
        }
    };

    const handleChange = (event) => {
        var parsedValue = parseGermanNumberString(event.target.value);
        props.onChange({ display: event.target.value, real: parsedValue });
        debouncedValidate();
    };

    const { setError, validate, thousandsSeparator, decimalPlaces, ...textFieldProps } = props;

    return (
        <TextField
            {...textFieldProps}
            error={props.error}
            helperText={props.error ? helperText : ""}
            value={props.value?.display}
            onChange={handleChange}
            onBlur={handleBlur}
            sx={{ ...props.sx, backgroundColor: props.disabled ? "#f3f3f3" : "inherit" }}
        />
    );
}

/**
 * Renders a TextField with validation and debounce functionality.
 *
 * This component is a wrapper around the Material-UI TextField component, adding validation and debounce
 * capabilities. It triggers validation on value change and when the input loses focus. The validation logic
 * is provided by the parent component through the `validate` prop. Debouncing is used to limit the rate of
 * validation checks, which is useful for reducing validations triggered by continuous typing.
 *
 * @param {Object} props - The component props.
 * @param {any} props.value - The current value of the input.
 * @param {Function} props.onChange - The function to call when the input value changes.
 * @param {Function} props.validate - The function to validate the input value. Should return a boolean.
 * @param {boolean} props.error - The error state of the input.
 * @param {Function} props.setError - The function to set the error state of the input.
 * @param {string} [props.errorText] - Optional text to display when there is an error.
 * @returns {JSX.Element} A TextField component with validation and debounce functionality.
 *
 * @example
 * <ValidatedTextField
 *   value={inputValue}
 *   onChange={(e) => setInputValue(e.target.value)}
 *   validate={(value) => value.length > 0}
 *   error={errorState}
 *   setError={setErrorState}
 *   errorText="This field is required"
 * />
 */
export function ValidatedTextField(props) {
    const _validate = () => {
        props.setError(!props.validate(props.value));
    };

    const debouncedValidate = useDebounce(() => {
        _validate();
    });

    const handleBlur = () => {
        _validate();
    };

    const handleChange = (event) => {
        props.onChange(event);
        debouncedValidate();
    };

    const { setError, errorText, validate, ...textFieldProps } = props;

    return (
        <TextField
            {...textFieldProps}
            error={props.error}
            helperText={props.error ? props.errorText : ""}
            value={props.value}
            onChange={handleChange}
            onBlur={handleBlur}
            sx={{ ...props.sx, backgroundColor: props.disabled ? "#f3f3f3" : "inherit" }}
        />
    );
}

export function BasicTextField(props) {
    return <TextField {...props} sx={{ ...props.sx, backgroundColor: props.disabled ? "#f3f3f3" : "inherit" }} />;
}

export function BasicSelectField(props) {
    const { FormControlProps, InputLabelProps, menuItems, customMenuItems, ...selectProps } = props;
    return (
        <FormControl {...props.FormControlProps}>
            <InputLabel disabled={props.disabled} {...props.InputLabelProps}>
                {props.label}
            </InputLabel>
            <Select {...selectProps} sx={{ ...props.sx, backgroundColor: props.disabled ? "#f3f3f3" : "inherit" }}>
                {props.menuItems === undefined
                    ? props.customMenuItems
                    : props.menuItems.map((item) =>
                          item ? (
                              <MenuItem key={item.name} value={item.value}>
                                  {item.name}
                              </MenuItem>
                          ) : null
                      )}
            </Select>
        </FormControl>
    );
}

/**
 * Renders a YearPicker with validation and debounce functionality.
 *
 * This component is a wrapper around the Material-UI Datepicker component, adding validation localization and debounce
 * capabilities. It triggers validation on value change and when the input loses focus.
 *
 * @param {Object} props - The component props.
 * @param {any} props.value - The current value of the input.
 * @param {Function} props.onChange - The function to call when the input value changes.
 * />
 */
export function YearPicker(props) {
    const {
        value,
        onChange,
        onBlur,
        size = "medium", // default size
        minYear,
        maxYear,
        disabled,
        name,
        error,
        ...other
    } = props;

    // Local state to keep track of the selected year
    const [selectedYear, setSelectedYear] = useState(value);

    // Update local state when the value prop changes
    useEffect(() => {
        setSelectedYear(value);
    }, [value]);

    // Convert the integer year to a dayjs object for the DatePicker
    const dateValue = selectedYear ? dayjs(`${selectedYear}`) : null;

    // Convert minYear and maxYear to dayjs objects
    const minDate = minYear ? dayjs(`${minYear}`) : dayjs("1900-01-01");
    const maxDate = maxYear ? dayjs(`${maxYear}`) : dayjs("2100-01-01");

    // Handle changes from the DatePicker
    const handleDateChange = (newDate) => {
        // Check manually if the value was set with the calendar button, this normally only triggers onChange,
        // but because the field has been set and the user can change other fields should also trigger onBlur
        // so for example: that year validation is performed, etc ...
        // This is a workaround to trigger onBlur by looking at properties the new year has compared to
        // the previous year, to not automatically trigger if the user types in 4 digits (i.e. a valid year but has not yet left the field) (simon)
        // ps. also triggers too often but better too often than not at a point where it should be triggered
        let newYear = null;
        if (
            (!selectedYear && newDate && newDate.year() > 999) ||
            (selectedYear && selectedYear < 100 && newDate && newDate.year() > 999) ||
            (selectedYear && newDate && selectedYear !== Math.floor(newDate.year() / 10))
        ) {
            newYear = newDate.year();
            setSelectedYear(newYear); 
            const event = { target: { name: name, value: newYear } };
            handleBlur(event);
        }

        if (newDate && newDate.isValid()) {
            newYear = newDate.year();
        }

        setSelectedYear(newYear); 

        if (onChange) {
            const event = {
                target: {
                    name: name,
                    value: newYear,
                },
            };
            onChange(event);
        }
    };

    // Handle blur event
    const handleBlur = (blurObject) => {
        // extract the year from the blurObject
        let newYear = Number.parseInt(blurObject.target.value);

        // if the year is not yet set (empty field), use the selected year
        let year = null;
        if (newYear !== null && newYear !== NaN) {
            year = newYear;
        } else {
            year = selectedYear;
        }

        if (onBlur) {
            const event = {
                target: {
                    name: name,
                    value: year,
                },
            };
            onBlur(event);
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                views={["year"]}
                value={dateValue}
                minDate={dayjs(minDate)}
                maxDate={dayjs(maxDate)}
                error={error}
                onChange={handleDateChange}
                slotProps={{
                    textField: {
                        size: size,
                        onBlur: handleBlur,
                        error: error,
                        disabled: props?.disabled,
                        InputProps: {
                            style: {
                                backgroundColor: props?.disabled ? "#f3f3f3" : "inherit",
                            },
                        },
                        ...other
                    },
                }}
                {...other} 
                disabled={disabled}
            />
        </LocalizationProvider>
    );
}
